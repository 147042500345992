import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbToast, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '../../services/toaster.service';
import { Toast } from '../../interface/Toast';
import { Alerts } from '../../constants/alert';

@Component({
    selector: 'app-toaster',
    imports: [NgbToastModule],
    templateUrl: './toaster.component.html',
    styleUrl: './toaster.component.scss'
})
export class ToasterComponent implements OnInit {
  @ViewChild('ngbToast') ngbToast!: NgbToast;
  public alertClass: { [key: string]: string } = Alerts.alertClass;
  public toaster: Toast | null = null;
toasterSubscription:any
  constructor(private toasterService: ToasterService) {}
  public ngOnInit(): void {
   this.toasterSubscription= this.toasterService.toasterTigger$.subscribe((val) => {
      this.toaster = val;
    });
  }

  public closeToaster(): void {
    this.toaster = null;
  }
  public ngOnDestroy(): void {
  this.toasterSubscription.unsubscribe()
  }
}
