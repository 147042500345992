import { Component, EventEmitter, Output } from '@angular/core';
import { ToasterService } from '../../services/toaster.service';

@Component({
    selector: 'app-confirmation-box',
    imports: [],
    templateUrl: './confirmation-box.component.html',
    styleUrl: './confirmation-box.component.scss'
})
export class ConfirmationBoxComponent {
  constructor(private toasterService: ToasterService) {}

  @Output() eventEmitter = new EventEmitter();
  show: boolean = false;
  title!: string;
  text!: string;
  resolve: any;
  statusSubscription:any
  ngOnInit(): void {
  this.statusSubscription=  this.toasterService.confirmTrigger$.subscribe((val) => {
      this.resolve = val.resolve;
      this.title = val.title;
      this.text = val.text;
      if (val) {
        this.show = true;
      }
      document.body.classList.add('modal-open');
    });
  }
  close(key?: string) {
    this.show = false;
    document.body.classList.remove('modal-open');
    if (key === 'yes') {
      this.resolve(false);
    }
  }

  // Handle form submission
  submit(key: string) {
    if (key === 'yes') {
      this.resolve(true);
    } else {
      this.resolve(false);
    }
    this.close();
  }
  public ngOnDestroy(): void {
    this.statusSubscription.unsubscribe();
  }
}
