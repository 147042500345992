import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { slideIn } from '../../../core/animations/animations.component';
import { NgxDynamicformsComponent } from '@feathersoft/ngx-dynamicforms';
import { FormGroup } from '@angular/forms';
import { ToasterService } from '../../../core/services/toaster.service';
import { LoaderService } from '../../../core/services/loader.service';
import { ApiConstant } from '../../../core/constants/api-constants';
import { Subject, takeUntil } from 'rxjs';
import { authService } from '../../../core/services/auth.service';
import { StorageService } from '../../../core/services/storage.service';

@Component({
    selector: 'app-edit-profile',
    imports: [
        NgxDynamicformsComponent
    ],
    templateUrl: './edit-profile.component.html',
    styleUrl: './edit-profile.component.scss',
    animations: [slideIn]
})
export class EditProfileComponent implements OnInit, OnDestroy {

  @ViewChild('lib') lib1!: NgxDynamicformsComponent;
  @Input() editType!: string;
  @Input() userDetails: any;
  @Output() closeClicked = new EventEmitter<void>();

  isEdit: boolean = false;
  title!: string;
  generetedJSON: any;
  unSubscribe = new Subject<void>();
  profileForm!: FormGroup;
  constructor(
    private apiService: ApiService,
    private toasterService: ToasterService,
    private loaderService: LoaderService,
    private storageService: StorageService,
    private authService: authService
  ) { }

  ngOnInit(): void {
    let jsonId;
    if (this.editType === 'edit-profile') {
      this.title = 'My Profile';
      jsonId = 100;
    } else {
      this.title = 'Change Password';
      this.isEdit = true;
      jsonId = 101;

    }
    document.body.classList.add('modal-open');
    this.generetedJSON=this.apiService.getDynamicJSON(jsonId);

  }

  geneartedForm(event: FormGroup) {
    this.profileForm = event;
    if (this.editType === 'edit-profile' && this.isEdit) {
      const patchValue = {
        first_name: this.userDetails?.first_name,
        last_name: this.userDetails?.last_name
      }
      this.profileForm.patchValue(patchValue);
    }
  }

  IdEmitterFun(event: string) { }

  submit() {
    if (this.editType === 'edit-profile') {
      this.lib1.ValidationId('my-profile');
      if (this.profileForm.valid) {
        this.saveProfileChange();
      }
    } else {
      this.lib1.ValidationId('change-pass');
      if (this.profileForm.valid) {
        this.savePasswordChange();
      }
    }
  }

  savePasswordChange() {
    const data = this.profileForm?.getRawValue();
    if (data?.previous_password === data?.proposed_password) {
      this.toasterService.alert.toast({
        type: 'warning',
        message: 'The new password must be different from the current password.',
      });
      return;
    }
    if (data?.proposed_password !== data?.confirm_Password) {
      this.toasterService.alert.toast({
        type: 'warning',
        message: 'The new password and the confirmation password do not match.',
      });
      return;
    }
    const payload = {
      previous_password: data.previous_password,
      proposed_password: data.proposed_password
    }
    const endpoint = this.apiService.getApiUrl(ApiConstant.SETTINGS.USER.CHNAGEPASSWORD);
    this.loaderService.displayLoader(true);
    this.apiService
      .ExecutePost(endpoint, payload)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe({
        next: (response: any) => {
          this.loaderService.displayLoader(false);
          if (response.success) {
            this.toasterService.alert.toast({
              type: 'success',
              message: response.message,
            });
            this.close();
          } else {
            this.toasterService.alert.toast({
              type: 'error',
              message: response.error,
            });
          }
        },
        error: (error: any) => {
          this.loaderService.displayLoader(false);
          this.toasterService.alert.toast({
            type: 'error',
            message: error.error,
          });
        },
      });
  }


  saveProfileChange() {
    const formValues = this.profileForm.getRawValue();
    let payload = {
      firstname: formValues?.first_name,
      lastname: formValues?.last_name,
      status: this.userDetails?.status.id,
      role: this.userDetails?.role,
    };
    const endpoint = this.apiService.getApiUrl(ApiConstant.SETTINGS.USER.ENDPOINT + `/${this.userDetails?.id}`);
    this.loaderService.displayLoader(true);
    this.apiService
      .ExecutePut(endpoint, payload)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe({
        next: (response: any) => {
          this.loaderService.displayLoader(false);
          if (response?.success) {
            this.updateProfile();
            this.toasterService.alert.toast({
              type: 'success',
              message: response.message,
            });
          } else {
            this.toasterService.alert.toast({
              type: 'error',
              message: response.message,
            });
          }
        },
        error: (error: any) => {
          this.loaderService.displayLoader(false);
          this.toasterService.alert.toast({
            type: 'error',
            message: error.message,
          });
        },
      });

  }

  updateProfile() {
    const formValues = this.profileForm.getRawValue();
    this.userDetails.first_name = formValues?.first_name;
    this.userDetails.last_name = formValues.last_name;
    const userProfile = this.authService.getUserInfo();
    userProfile.display_name = `${formValues?.first_name} ${formValues.last_name}`;
    this.storageService.set('profile', userProfile);
    this.isEdit = false;
  }

  close() {
    this.closeClicked.emit();
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
    document.body.classList.remove('modal-open');
  }

}
