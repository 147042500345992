import { Component } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
    selector: 'app-loader',
    imports: [],
    templateUrl: './loader.component.html',
    styleUrl: './loader.component.scss'
})
export class LoaderComponent {
  showLoader!: boolean;
  message!: string;
statusSubscription:any
  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
  this.statusSubscription=  this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });
  }
  public ngOnDestroy(): void {
   this.statusSubscription.unsubscribe();
  }
}
