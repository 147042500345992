import { Component, OnInit } from '@angular/core';
import { SideMenuComponent } from '../../core/components/side-menu/side-menu.component';
import { HeaderComponent } from '../../core/components/header/header.component';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Menu } from '../../core/interface/menu';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.scss',
    imports: [SideMenuComponent, HeaderComponent, CommonModule, RouterOutlet]
})
export class DashboardComponent implements OnInit {
  public menu: Menu[] = [];

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ menu }) => {
      this.configureMenuItems(menu?.data);
    });
  }

  configureMenuItems(data: any) {
    if (data?.inventory) {
      const menu: Menu = {
        id: 0,
        value: 'Inventory',
        child: data.inventory.menu || [],
        route: 'inventory',
      };
      this.menu.push(menu);
    }
  }
}
