import { CURRENTDATE } from "../app-constants";

//Project initialization step in project
export const ProjectbspDynamicJSON = [{
    jsonId: 50,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
        {
            control: [],
            type: '',
            name: 'wrapper_576',
            classname: 'row',
            wrapperType: 'BOX',
            id: 'wrapper_790',
            fields: [

                {
                    control: [
                        {
                            type: 'text',
                            name: 'project_title',
                            id: 'project_title',
                            value: '',
                            placeholder: '',
                            multiline: false,
                            classname: 'form-control ',
                            regularExpression: '',
                            disabled: false,
                            required: true,
                            errorMessage: { noData: 'This field is required', regexFail: '' },
                            validationButtonType: 'initial-submit',
                            rows: 0,
                            columns: 0,
                            onDemandError: false,
                            customValidation: [],
                            multiError: false,
                            applicationError: '',
                            wrapperClassName: '',
                            requiredErrorMessage: 'This field is required',
                            showInputLabel: true,
                            labelContent: 'Project Title',
                            labelClass: 'form-label mandatory',
                            isLabelAfter: false,
                            showAdditionalInputContent: false,
                            additionalInputContent: '',
                            isAdditonalInputAfter: true,
                            draggable: false,
                        },
                    ],
                    type: '',
                    name: 'wrapper_50',
                    classname: 'col-12 mb-3',
                    wrapperType: 'BOX',
                    id: 'wrapper_808',
                    fields: [],
                    head: '',
                    tabHeadClass: '',
                    draggable: false,
                },
                {
                    control: [
                        {
                            type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                            name: 'project_init_by',
                            id: 'project_init_by',
                            validationButtonType: 'initial-submit',
                            classname: '',
                            required: true,
                            apiEndPoints: [
                                {
                                    type: 'init',
                                    method: 'get',
                                    url: 'user_management/users_list',
                                },
                            ],

                            multiple: false,
                            isClearable: false,
                            isStatus: false,
                            search: true,
                            singleSelect: true,
                            showLabel: 'value',
                            errorMessage: { noData: 'This field is required' },
                            value: '',
                            imageUrl: '',
                            disabled: false,
                            wrapperClassName: '',
                            showInputLabel: true,
                            labelContent: 'Initiated By',
                            labelClass: 'form-label mandatory',
                            isLabelAfter: false,
                            showAdditionalInputContent: false,
                            additionalInputContent: '',
                            isAdditonalInputAfter: true,
                            draggable: false,
                        },
                    ],
                    type: '',
                    name: 'wrapper_290',
                    classname: 'col-12 mb-3',
                    wrapperType: 'BOX',
                    id: 'wrapper_654',
                    fields: [],
                    head: '',
                    tabHeadClass: '',
                    draggable: false,
                },
                {
                    control: [
                        {
                            type: 'datepicker_v2',
                            name: 'project_start_date',
                            id: 'project_start_date',
                            validationButtonType: 'initial-submit',
                            range: false,
                            value: '',
                            multiple: false,
                            multiAmount: 3,
                            keyboardInput: false,
                            startDateReadOnly: false,
                            endDateReadOnly: false,
                            fordisabledStart: '',
                            fordisabledEnd: '',
                            timer: false,
                            hourFormat: 24,
                            placeholder: 'DD-MM-YYYY',
                            startDatePlaceHolder: 'MM/DD/YYYY',
                            inputClassName: 'form-control date-input',
                            selectedDateClassName: 'selectedDate',
                            arrowClassName: 'arrow',
                            dayClassName: 'dd',
                            todayClassName: 'now',
                            monthClassName: 'mtn',

                            required: true,
                            classname: '',
                            errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                            dateFormat: 'dd/mm/yyyy',
                            disabled: false,
                            wrapperClassName: '',
                            locale: '',
                            dateSeperator: '',
                            inputDateFormat: false,
                            isHostParent: false,
                            showInputLabel: true,
                            labelContent: 'Start Date',
                            labelClass: 'form-label mandatory',
                            isLabelAfter: false,
                            showAdditionalInputContent: false,
                            additionalInputContent: '',
                            isAdditonalInputAfter: true,
                            draggable: false,
                        },
                    ],
                    type: '',
                    name: 'wrapper_209',
                    classname: 'col-6 mb-3',
                    wrapperType: 'BOX',
                    id: 'wrapper_778',
                    fields: [],
                    head: '',
                    tabHeadClass: '',
                    draggable: false,
                },
                {
                    control: [
                        {
                            type: 'datepicker_v2',
                            name: 'project_end_date',
                            id: 'project_end_date',
                            validationButtonType: '',
                            range: false,
                            value: '',
                            multiple: false,
                            multiAmount: 3,
                            keyboardInput: false,
                            startDateReadOnly: false,
                            endDateReadOnly: false,
                            fordisabledStart: '',
                            fordisabledEnd: '',
                            timer: false,
                            hourFormat: 24,
                            placeholder: 'DD-MM-YYYY',
                            startDatePlaceHolder: 'MM/DD/YYYY',
                            inputClassName: 'form-control date-input',
                            selectedDateClassName: 'selectedDate',
                            arrowClassName: 'arrow',
                            dayClassName: 'dd',
                            todayClassName: 'now',
                            monthClassName: 'mtn',

                            required: false,
                            classname: '',
                            errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                            dateFormat: 'dd/mm/yyyy',
                            disabled: false,
                            wrapperClassName: '',
                            locale: '',
                            dateSeperator: '',
                            inputDateFormat: false,
                            isHostParent: false,
                            showInputLabel: true,
                            labelContent: 'End Date',
                            labelClass: 'form-label',
                            isLabelAfter: false,
                            showAdditionalInputContent: false,
                            additionalInputContent: '',
                            isAdditonalInputAfter: true,
                            draggable: false,
                        },
                    ],
                    type: '',
                    name: 'wrapper_643',
                    classname: 'col-6 mb-3',
                    wrapperType: 'BOX',
                    id: 'wrapper_92',
                    fields: [],
                    head: '',
                    tabHeadClass: '',
                    draggable: false,
                },
            ],
            head: '',
            tabHeadClass: '',
            draggable: false,
        },
    ],
    type: '',
    classname: 'form-container',
},
//Plasmid information step in project
{
    jsonId: 51,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
        {
            control: [],
            type: '',
            name: 'wrapper_871',
            classname: 'row',
            wrapperType: 'BOX',
            id: 'wrapper_17',
            fields: [

            ],
            head: '',
            tabHeadClass: '',
            draggable: false,
        },
        {
            control: [],
            type: '',
            name: 'wrapper_73',
            classname: 'project-table',
            wrapperType: 'BOX',
            id: 'wrapper_364',
            fields: [
                {
                    control: [],
                    type: '',
                    name: 'plasmidtable',
                    classname: 'table project-ini-table',
                    wrapperType: 'TABLE',
                    id: 'plasmidtable',
                    fields: [],
                    head: '',
                    tabHeadClass: '',
                    draggable: false,
                    tableFields: {
                        showHead: true,
                        isVertical: false,
                        isIteratable: true,
                        addButton: {
                            classname: 'add-expand',
                            name: '',
                            value: '<div class="custom-tooltip"><p>Add Row</div>',
                        },
                        removeButton: {
                            classname: 'add-minus',
                            name: '',
                            value: '<div class="custom-tooltip"><p>Remove Row</div>',
                        },
                        tableHead: {
                            heading: [
                                { value: 'PLASMID TYPE', classname: '' },
                                { value: 'PLASMID', classname: '' },
                                { value: 'PLASMID ID', classname: '' },
                                { value: 'SOURCE', classname: '' },
                                { value: 'ANTIBIOTIC RESISTANCE', classname: '' },
                                { value: 'STORAGE LOCATION', classname: '' },
                                { value: 'ACTIONS', classname: '' },
                                { value: '', classname: '' },
                            ],
                            classname: '',
                        },
                        initRecordCount: 1,
                        showRecordCount: false,
                        recordCounterConfig: { classname: '', adjValue: '', isAdjValueAfter: false },
                        tableRow: {
                            name: '',
                            classname: 'trclass',
                            tableCols: [
                                {
                                    fields: {
                                        control: [
                                            {
                                                type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                                                name: 'plasmid_type',
                                                id: 'plasmid_type',
                                                validationButtonType: 'plasmid-submit',
                                                classname: '',
                                                required: true,
                                                apiEndPoints: [
                                                    {
                                                        type: 'init',
                                                        method: 'post',
                                                        url: 'inventory-plasmids/inventory-sub-types',
                                                        payload: {
                                                            perma: ['plasmids'],
                                                        },
                                                    },
                                                    {
                                                        type: 'change',
                                                        method: 'get',
                                                        Mapto: [
                                                            {
                                                                id: 'main.wrapper_364.plasmidtable.${i}.Group_Details.plasmid',
                                                                displayKey: 'value',
                                                                urlParameterValues: { id: null },
                                                            },
                                                        ],
                                                        url: 'inventory-plasmids/plasmids-list/${id}',
                                                    },
                                                ],
                                                options: [],
                                                multiple: false,
                                                isClearable: false,
                                                isStatus: false,
                                                search: true,
                                                singleSelect: true,
                                                showLabel: 'value',
                                                errorMessage: { noData: 'This field is required' },
                                                value: '',
                                                imageUrl: '',
                                                disabled: false,
                                                wrapperClassName: '',
                                                connectedArrays: [],
                                                showInputLabel: true,
                                                labelContent: '',
                                                labelClass: 'form-label d-none',
                                                isLabelAfter: false,
                                                showAdditionalInputContent: false,
                                                additionalInputContent: '',
                                                isAdditonalInputAfter: true,
                                                draggable: false,
                                            },
                                        ],
                                        head: '',
                                        name: 'box_734',
                                        id: 'box_582',
                                        wrapperType: 'BOX',
                                        fields: [],
                                        type: '',
                                        classname: '',
                                    },
                                    classname: '',
                                },

                                {
                                    fields: {
                                        control: [
                                            {
                                                required: true,
                                                validationButtonType: 'plasmid-submit',
                                                type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                                                name: 'plasmid',
                                                id: 'plasmid',
                                                classname: '',
                                                apiEndPoints: [
                                                    {
                                                        type: 'change',
                                                        method: 'get',
                                                        isGet: true,
                                                        Mapto: [
                                                            {
                                                                id: 'main.wrapper_364.plasmidtable.${i}.box_716.plasmid_id',
                                                                displayKey: 'change',
                                                                urlParameterValues: { id: null, patchProperty: 'plasmids_id' },
                                                            },
                                                            {
                                                                id: 'main.wrapper_364.plasmidtable.${i}.box_716.source',
                                                                displayKey: 'change',
                                                                urlParameterValues: { id: null, patchProperty: 'source' },
                                                            },
                                                            {
                                                                id: 'main.wrapper_364.plasmidtable.${i}.box_716.antibiotic_resistence',
                                                                displayKey: 'change',
                                                                urlParameterValues: { id: null, patchProperty: 'bacterial_resistance' },
                                                            },
                                                            {
                                                                id: 'main.wrapper_364.plasmidtable.${i}.box_716.plasmid_info',
                                                                displayKey: 'change',
                                                                urlParameterValues: { id: null, patchProperty: 'plasmid_info' },
                                                            },
                                                        ],
                                                        url: 'inventory-plasmids/${id}',
                                                    },
                                                ],
                                                options: [],
                                                multiple: false,
                                                isClearable: true,
                                                isStatus: false,
                                                search: true,
                                                singleSelect: true,
                                                showLabel: 'value',
                                                disabled: false,
                                                wrapperClassName: '',
                                                connectedArrays: [],
                                                showInputLabel: true,
                                                labelContent: '',
                                                labelClass: 'form-label d-none',
                                                isLabelAfter: false,
                                                showAdditionalInputContent: false,
                                                additionalInputContent: '',
                                                isAdditonalInputAfter: true,
                                                draggable: false,
                                                errorMessage: {
                                                    noData: 'This field is required',
                                                },
                                            },
                                            {
                                                type: 'button',
                                                id: 'infoicon',
                                                value: '',
                                                buttonType: 'plasmid_info',
                                                classname: 'info',
                                                divClass: '',
                                                controlEndPoints: [],
                                                disabled: false,
                                                validationEndPoints: [],
                                                invalid: false,
                                                name: 'infoicon',
                                                wrapperClassName: '',
                                                draggable: false,
                                            },
                                        ],
                                        name: 'groupDetails',
                                        classname: ['action-wrap'],
                                        id: 'Group_Details',
                                        wrapperType: 'BOX',
                                        fields: [],
                                    },
                                },
                                {
                                    fields: {
                                        control: [
                                            {
                                                type: 'text',
                                                name: 'plasmid_id',
                                                id: 'plasmid_id',
                                                value: '-',
                                                placeholder: '',
                                                multiline: false,
                                                classname: 'form-control ',
                                                regularExpression: '',
                                                disabled: true,
                                                required: false,
                                                errorMessage: {
                                                    noData: 'This field is required',
                                                    regexFail: '',
                                                },
                                                validationButtonType: '',
                                                rows: 0,
                                                columns: 0,
                                                onDemandError: false,
                                                customValidation: [],
                                                multiError: false,
                                                applicationError: '',
                                                wrapperClassName: '',
                                                requiredErrorMessage: 'This field is required',
                                                showInputLabel: true,
                                                labelContent: '',
                                                labelClass: 'form-label',
                                                isLabelAfter: false,
                                                showAdditionalInputContent: false,
                                                additionalInputContent: '',
                                                isAdditonalInputAfter: true,
                                                draggable: false,
                                            },
                                            {
                                                type: 'text',
                                                name: 'plasmid_info',
                                                id: 'plasmid_info',
                                                value: '',
                                                placeholder: '',
                                                multiline: false,
                                                classname: 'd-none',
                                                regularExpression: '',
                                                disabled: true,
                                                required: false,
                                                errorMessage: {
                                                    noData: 'This field is required',
                                                    regexFail: '',
                                                },
                                                validationButtonType: '',
                                                rows: 0,
                                                columns: 0,
                                                onDemandError: false,
                                                customValidation: [],
                                                multiError: false,
                                                applicationError: '',
                                                wrapperClassName: '',
                                                requiredErrorMessage: 'This field is required',
                                                showInputLabel: true,
                                                labelContent: '',
                                                labelClass: 'd-none',
                                                isLabelAfter: false,
                                                showAdditionalInputContent: false,
                                                additionalInputContent: '',
                                                isAdditonalInputAfter: true,
                                                draggable: false,
                                            },
                                        ],

                                        name: 'box_644',
                                        id: 'box_716',
                                        wrapperType: 'BOX',
                                        fields: [],
                                        classname: '',
                                        addButton: { classname: '', value: '' },
                                        removeButton: { classname: '', value: '' },
                                        wrapperClassName: '',
                                    },
                                    classname: '',
                                },

                                {
                                    fields: {
                                        control: [
                                            {
                                                type: 'text',
                                                name: 'source',
                                                id: 'source',
                                                value: '-',
                                                placeholder: '',
                                                multiline: false,
                                                classname: 'form-control ',
                                                regularExpression: '',
                                                disabled: true,
                                                required: false,
                                                errorMessage: {
                                                    noData: 'This field is required',
                                                    regexFail: '',
                                                },
                                                validationButtonType: '',
                                                rows: 0,
                                                columns: 0,
                                                onDemandError: false,
                                                customValidation: [],
                                                multiError: false,
                                                applicationError: '',
                                                wrapperClassName: '',
                                                requiredErrorMessage: 'This field is required',
                                                showInputLabel: true,
                                                labelContent: '',
                                                labelClass: 'form-label',
                                                isLabelAfter: false,
                                                showAdditionalInputContent: false,
                                                additionalInputContent: '',
                                                isAdditonalInputAfter: true,
                                                draggable: false,
                                            },
                                        ],
                                        name: 'box_644',
                                        id: 'box_716',
                                        wrapperType: 'BOX',
                                        fields: [],
                                        classname: '',
                                        addButton: { classname: '', value: '' },
                                        removeButton: { classname: '', value: '' },
                                        wrapperClassName: '',
                                    },
                                    classname: '',
                                },
                                {
                                    fields: {
                                        control: [
                                            {
                                                type: 'text',
                                                name: 'antibiotic_resistence',
                                                id: 'antibiotic_resistence',
                                                value: '-',
                                                placeholder: '',
                                                multiline: false,
                                                classname: 'form-control ',
                                                regularExpression: '',
                                                disabled: true,
                                                required: false,
                                                errorMessage: {
                                                    noData: 'This field is required',
                                                    regexFail: '',
                                                },
                                                validationButtonType: '',
                                                rows: 0,
                                                columns: 0,
                                                onDemandError: false,
                                                customValidation: [],
                                                multiError: false,
                                                applicationError: '',
                                                wrapperClassName: '',
                                                requiredErrorMessage: 'This field is required',
                                                showInputLabel: true,
                                                labelContent: '',
                                                labelClass: 'form-label',
                                                isLabelAfter: false,
                                                showAdditionalInputContent: false,
                                                additionalInputContent: '',
                                                isAdditonalInputAfter: true,
                                                draggable: false,
                                            },
                                        ],
                                        name: 'box_644',
                                        id: 'box_716',
                                        wrapperType: 'BOX',
                                        fields: [],
                                        classname: '',
                                        addButton: { classname: '', value: '' },
                                        removeButton: { classname: '', value: '' },
                                        wrapperClassName: '',
                                    },
                                    classname: '',
                                },
                                {
                                    fields: {
                                        control: [
                                            {
                                                type: 'button',
                                                id: 'infoicon',
                                                value: 'View Location',
                                                buttonType: 'storage_info',
                                                classname: 'info-icon',
                                                divClass: '',
                                                controlEndPoints: [],
                                                disabled: false,
                                                validationEndPoints: [],
                                                invalid: false,
                                                name: 'infoicon',
                                                wrapperClassName: '',
                                                draggable: false,
                                            },
                                        ],
                                        name: 'box_644',
                                        id: 'box_716',
                                        wrapperType: 'BOX',
                                        fields: [],
                                        classname: '',
                                        addButton: { classname: '', value: '' },
                                        removeButton: { classname: '', value: '' },
                                        wrapperClassName: '',
                                    },
                                    classname: '',
                                },
                                {
                                    fields: {
                                        control: [
                                            {
                                                type: 'button',
                                                id: 'plasmidmap',
                                                value: '<div class="custom-tooltip"><p>View Plasmid Map</p></div>',
                                                buttonType: 'plasmid_map',
                                                classname: 'plasmid-view',
                                                divClass: '',
                                                controlEndPoints: [],
                                                disabled: false,
                                                validationEndPoints: [],
                                                invalid: false,
                                                name: 'plasmidmap',
                                                wrapperClassName: '',
                                                draggable: false,
                                            },
                                        ],
                                        name: 'box_985',
                                        id: 'box_89',
                                        wrapperType: 'BOX',
                                        fields: [],
                                        type: '',
                                        classname: 'actions',
                                        addButton: { classname: '', value: '<div class="custom-tooltip"><p>Add Row</div>' },
                                        removeButton: { classname: '', value: '<div class="custom-tooltip"><p>Remove Row</div>' },
                                        wrapperClassName: '',
                                    },
                                    classname: '',
                                },
                            ],
                        },
                    },
                },
            ],
            head: '',
            tabHeadClass: '',
            draggable: false,
        },
        {
            control: [],
            type: '',
            name: 'wrapper_133',
            classname: 'row',
            wrapperType: 'BOX',
            id: 'wrapper_329',
            fields: [
                {
                    control: [
                        {
                            type: 'text',
                            name: 'exprmntal_notes',
                            id: 'exprmntal_notes',
                            value: '',
                            placeholder: '',
                            multiline: true,
                            classname: 'form-control textarea',
                            regularExpression: '',
                            disabled: false,
                            required: false,
                            errorMessage: { noData: 'This field is required', regexFail: '' },
                            validationButtonType: '',
                            rows: 0,
                            columns: 0,
                            onDemandError: false,
                            customValidation: [],
                            multiError: false,
                            applicationError: '',
                            wrapperClassName: '',
                            requiredErrorMessage: 'This field is required',
                            showInputLabel: true,
                            labelContent: 'Experimental Notes/Comments',
                            labelClass: 'form-label',
                            isLabelAfter: false,
                            showAdditionalInputContent: false,
                            additionalInputContent: '',
                            isAdditonalInputAfter: true,
                            draggable: false,
                        },
                    ],
                    type: '',
                    name: 'wrapper_588',
                    classname: 'col-12 mb-3',
                    wrapperType: 'BOX',
                    id: 'wrapper_345',
                    fields: [],
                    head: '',
                    tabHeadClass: '',
                },
            ],
            head: '',
            tabHeadClass: '',
            draggable: false,
        },
    ],
    type: '',
    classname: '',
},

//Project listing filter -- used commonly in all the projects filter( bsp,app,pep,msp)
{
    "jsonId": 52,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
      {
        "control": [],
        "type": "",
        "name": "wrapper_854",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_543",
        "fields": [
          {
            "control": [
              {
                "type": "dropdown",
                "name": "project_type",
                "id": "project_type",
                "validationButtonType": "",
                "classname": "",
                "required": false,
                "apiEndPoints": [],
                "options": [
                  {
                    "id": 1,
                    "value": "Bacterial Stock Preparation"
                  },
                  {
                    "id": 2,
                    "value": "Plasmid Extraction and Purification"
                  },
                  {
                    "id": 3,
                    "value": "Antibody Production and Purification"
                  },
                  {
                    "id": 4,
                    "value": "Mammalian Cell Lines Stock Preparation"
                  }
                ],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": true,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "showInputLabel": true,
                "labelContent": "Project Type",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false,
                "controlPoints": [
                  {
                    "type": "change",
                    "value": {
                      "id": 2,
                      "value": "Plasmid Extraction and Purification"
                    },
                    "Mapto": []
                  },
                  {
                    "type": "change",
                    "value": {
                      "id": 1,
                      "value": "Bacterial Stock Preparation"
                    },
                    "Mapto": []
                  }
                ]
              }
            ],
            "type": "",
            "name": "wrapper_1",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_6",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "project_title",
                "id": "project_title",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Project Title",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_895",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_992",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "project_id",
                "id": "project_id",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Project Id",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_895",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_992",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "datepicker_v2",
                "name": "project_start_date",
                "id": "project_start_date",
                "validationButtonType": "",
                "range": true,
                "value": "",
                "multiple": false,
                "multiAmount": 3,
                "keyboardInput": false,
                "startDateReadOnly": false,
                "endDateReadOnly": false,
                "fordisabledStart": "",
                "fordisabledEnd": "",
                "timer": false,
                "hourFormat": 24,
                "placeholder": "DD-MM-YYYY",
                "startDatePlaceHolder": "MM/DD/YYYY",
                "inputClassName": "form-control date-input",
                "selectedDateClassName": "selectedDate",
                "arrowClassName": "arrow",
                "dayClassName": "dd",
                "todayClassName": "now",
                "monthClassName": "mtn",
                "regularExpression": "/^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}s+tos+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}$/",
                "required": false,
                "classname": "",
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": "Date is not valid!!"
                },
                "dateFormat": "dd/mm/yyyy",
                "disabled": false,
                "wrapperClassName": "",
                "locale": "",
                "dateSeperator": "",
                "inputDateFormat": false,
                "isHostParent": false,
                "showInputLabel": true,
                "labelContent": "Project Start Date",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_749",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_285",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
                "type": "datepicker_v2",
                "name": "project_end_date",
                "id": "project_end_date",
                "validationButtonType": "submit",
                "range": true,
                "value": "",
                "multiple": false,
                "multiAmount": 3,
                "keyboardInput": false,
                "startDateReadOnly": false,
                "endDateReadOnly": false,
                "fordisabledStart": "",
                "fordisabledEnd": "",
                "timer": false,
                "hourFormat": 24,
                "placeholder": "DD-MM-YYYY",
                "startDatePlaceHolder": "MM/DD/YYYY",
                "inputClassName": "form-control date-input",
                "selectedDateClassName": "selectedDate",
                "arrowClassName": "arrow",
                "dayClassName": "dd",
                "todayClassName": "now",
                "monthClassName": "mtn",
                "regularExpression": "/^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}s+tos+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}$/",
                "required": false,
                "classname": "",
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": "Date is not valid!!"
                },
                "dateFormat": "dd/mm/yyyy",
                "disabled": false,
                "wrapperClassName": "",
                "locale": "",
                "dateSeperator": "",
                "inputDateFormat": false,
                "isHostParent": false,
                "showInputLabel": true,
                "labelContent": "Project End Date",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_749",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_286",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
                "type": "dropdown",
                "name": "plasmid_type",
                "id": "plasmid_type",
                "validationButtonType": "",
                "classname": "",
                "required": false,
                "apiEndPoints": [
                  {
                    "type": "init",
                    "method": "post",
                    "url": "inventory-plasmids/inventory-sub-types",
                    "payload": {
                      "perma": [
                        "plasmids"
                      ]
                    }
                  }
                ],
                "options": [],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": true,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "showInputLabel": true,
                "labelContent": "Plasmid Type",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_854",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_539",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "plasmid_name",
                "id": "plasmid_name",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Plasmid Name",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_507",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_370",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "dropdown",
                "name": "bacterial_resistance",
                "id": "bacterial_resistance",
                "validationButtonType": "",
                "classname": "",
                "required": false,
                "apiEndPoints": [
                  {
                    "type": "init",
                    "method": "post",
                    "url": "masterdata",
                    "payload": {
                      "data_types": [
                        "bact_rest"
                      ]
                    }
                  }
                ],
                "options": [],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": true,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "showInputLabel": true,
                "labelContent": "Bacterial Resistance",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_730",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_333",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "dropdown",
                "name": "status",
                "id": "status",
                "validationButtonType": "",
                "classname": "status-dropdown",
                "required": false,
                "apiEndPoints": [],
                "options": [
                  {
                    "id": 1,
                    "value": "Project Initiated",
                    "className": "project-initiated"
                  },
                  {
                    "id": 2,
                    "value": "Project workflow Inprogress",
                    "className": "project-progress"
                  },
                  {
                    "id": 3,
                    "value": "Project Completed",
                    "className": "project-completed"
                  },
                  {
                    "id": 4,
                    "value": "Deleted",
                    "className": "deleted-status"
                  },
                  {
                    "id": 5,
                    "value": "Project End",
                    "className": "project-end"
                  }
                ],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": true,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "showInputLabel": true,
                "labelContent": "Status",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_97",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_513",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      }
    ],
    "type": "",
    "classname": ""
  },
//bacterial stock preparation step in project
{
    "jsonId": 53,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
      {
        "control": [],
        "type": "",
        "name": "wrapper_871",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_17",
        "fields": [
          {
            "control": [
              {
                "type": "dropdown",
                "name": "protocol",
                "id": "protocol",
                "validationButtonType": "BSP-submit",
                "classname": "",
                "required": true,
                "apiEndPoints": [
                  {
                    "type": "init",
                    "method": "get",
                    "url": "protocol/protocol-list"
                  },
                  {
                    "type": "change",
                    "method": "get",
                    "Mapto": [
                      {
                        "id": "",
                        "displayKey": "value",
                        "urlParameterValues": {
                          "id": null
                        }
                      }
                    ],
                    "url": "protocol/${id}"
                  }
                ],
                "options": [],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": true,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showBlankInPlaceholder": false,
                "blankDataList": [],
                "showInputLabel": true,
                "labelContent": "Protocol",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_392",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_650",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [],
            "type": "",
            "name": "wrapper_916",
            "classname": "col-md-1 mt-4",
            "wrapperType": "BOX",
            "id": "wrapper_62",
            "fields": [
              {
                "control": [
                  {
                    "type": "button",
                    "id": "info",
                    "value": "<div class=\"custom-tooltip\"><p>View </p></div>",
                    "buttonType": "protocol_info",
                    "classname": "view",
                    "divClass": "",
                    "controlEndPoints": [],
                    "disabled": false,
                    "validationEndPoints": [],
                    "invalid": false,
                    "name": "info",
                    "wrapperClassName": "",
                    "draggable": false
                  }
                ],
                "type": "",
                "name": "wrapper_913",
                "classname": "actions position-relative",
                "wrapperType": "BOX",
                "id": "wrapper_113",
                "fields": [],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
              }
            ],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "wrapperType": "REGULAR",
        "fields": [
          {
            "control": [
              {
                "type": "label",
                "name": "Plasmid Info",
                "classname": "",
                "for": "",
                "required": false,
                "wrapperClassName": "",
                "id": "label_198",
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_391",
            "classname": "add-heading heading-without-icon",
            "wrapperType": "BOX",
            "id": "wrapper_108",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          }
        ],
        "type": "",
        "classname": "col-md-12"
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_73",
        "classname": "plasmid-prep-table",
        "wrapperType": "BOX",
        "id": "wrapper_364",
        "fields": [
          {
            "control": [],
            "type": "",
            "name": "plasmidtable",
            "classname": "table",
            "wrapperType": "TABLE",
            "id": "plasmidtable",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false,
            "tableFields": {
              "showHead": true,
              "isVertical": false,
              "isIteratable": false,
              "addButton": {
                "classname": "btn btn-primary",
                "name": "",
                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
              },
              "removeButton": {
                "classname": "btn btn-danger",
                "name": "",
                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
              },
              "tableHead": {
                "heading": [
                  {
                    "value": "PLASMID TYPE",
                    "classname": ""
                  },
                  {
                    "value": "PLASMID",
                    "classname": ""
                  },
                  {
                    "value": "ANTIBIOTIC RESISTANCE",
                    "classname": ""
                  }
                ],
                "classname": ""
              },
              "initRecordCount": 1,
              "showRecordCount": false,
              "recordCounterConfig": {
                "classname": "",
                "adjValue": "",
                "isAdjValueAfter": false
              },
              "tableRow": {
                "name": "",
                "classname": "trclass",
                "tableCols": [
                  {
                    "fields": {
                      "control": [
                        {
                          "type": "text",
                          "name": "plasmid_type",
                          "id": "plasmid_type",
                          "value": "-",
                          "placeholder": "",
                          "multiline": false,
                          "classname": "form-control ",
                          "regularExpression": "",
                          "disabled": true,
                          "required": false,
                          "errorMessage": {
                            "noData": "This field is required",
                            "regexFail": ""
                          },
                          "validationButtonType": "",
                          "rows": 0,
                          "columns": 0,
                          "onDemandError": false,
                          "customValidation": [],
                          "multiError": false,
                          "applicationError": "",
                          "wrapperClassName": "",
                          "requiredErrorMessage": "This field is required",
                          "showInputLabel": true,
                          "labelContent": "",
                          "labelClass": "form-label",
                          "isLabelAfter": false,
                          "showAdditionalInputContent": false,
                          "additionalInputContent": "",
                          "isAdditonalInputAfter": true,
                          "draggable": false
                        },
                        {
                          "type": "text",
                          "name": "plasmid_info",
                          "id": "plasmid_info",
                          "value": "",
                          "placeholder": "",
                          "multiline": false,
                          "classname": "d-none",
                          "regularExpression": "",
                          "disabled": true,
                          "required": false,
                          "errorMessage": {
                            "noData": "This field is required",
                            "regexFail": ""
                          },
                          "validationButtonType": "",
                          "rows": 0,
                          "columns": 0,
                          "onDemandError": false,
                          "customValidation": [],
                          "multiError": false,
                          "applicationError": "",
                          "wrapperClassName": "",
                          "requiredErrorMessage": "This field is required",
                          "showInputLabel": true,
                          "labelContent": "",
                          "labelClass": "d-none",
                          "isLabelAfter": false,
                          "showAdditionalInputContent": false,
                          "additionalInputContent": "",
                          "isAdditonalInputAfter": true,
                          "draggable": false
                        }
                      ],
                      "name": "box_644",
                      "id": "box_716",
                      "wrapperType": "BOX",
                      "fields": [],
                      "classname": "",
                      "addButton": {
                        "classname": "",
                        "value": ""
                      },
                      "removeButton": {
                        "classname": "",
                        "value": ""
                      },
                      "wrapperClassName": ""
                    },
                    "classname": ""
                  },
                  {
                    "fields": {
                      "control": [
                        {
                          "type": "text",
                          "name": "plasmid",
                          "id": "plasmid",
                          "value": "-",
                          "placeholder": "",
                          "multiline": false,
                          "classname": "form-control form-control-plasmid",
                          "regularExpression": "",
                          "disabled": true,
                          "required": false,
                          "errorMessage": {
                            "noData": "This field is required",
                            "regexFail": ""
                          },
                          "validationButtonType": "",
                          "rows": 0,
                          "columns": 0,
                          "onDemandError": false,
                          "customValidation": [],
                          "multiError": false,
                          "applicationError": "",
                          "wrapperClassName": "",
                          "requiredErrorMessage": "This field is required",
                          "showInputLabel": true,
                          "labelContent": "",
                          "labelClass": "form-label",
                          "isLabelAfter": false,
                          "showAdditionalInputContent": false,
                          "additionalInputContent": "",
                          "isAdditonalInputAfter": true,
                          "draggable": false
                        },
                        {
                          "type": "button",
                          "id": "infoicon",
                          "value": "",
                          "buttonType": "plasmid_info",
                          "classname": "info",
                          "divClass": "",
                          "controlEndPoints": [],
                          "disabled": false,
                          "validationEndPoints": [],
                          "invalid": false,
                          "name": "infoicon",
                          "wrapperClassName": "",
                          "draggable": false
                        },
                        {
                          "type": "button",
                          "id": "infoiconview",
                          "value": "",
                          "buttonType": "plasmid_map",
                          "classname": "plasmid-view",
                          "divClass": "",
                          "controlEndPoints": [],
                          "disabled": false,
                          "validationEndPoints": [],
                          "invalid": false,
                          "name": "infoicon",
                          "wrapperClassName": "",
                          "draggable": false
                        }
                      ],
                      "name": "box_644",
                      "id": "box_716",
                      "wrapperType": "BOX",
                      "fields": [],
                      "classname": "plasmid",
                      "addButton": {
                        "classname": "",
                        "value": ""
                      },
                      "removeButton": {
                        "classname": "",
                        "value": ""
                      },
                      "wrapperClassName": ""
                    },
                    "classname": ""
                  },
                  {
                    "fields": {
                      "control": [
                        {
                          "type": "text",
                          "name": "bacterial_resistance",
                          "id": "bacterial_resistance",
                          "value": "-",
                          "placeholder": "",
                          "multiline": false,
                          "classname": "form-control ",
                          "regularExpression": "",
                          "disabled": true,
                          "required": false,
                          "errorMessage": {
                            "noData": "This field is required",
                            "regexFail": ""
                          },
                          "validationButtonType": "",
                          "rows": 0,
                          "columns": 0,
                          "onDemandError": false,
                          "customValidation": [],
                          "multiError": false,
                          "applicationError": "",
                          "wrapperClassName": "",
                          "requiredErrorMessage": "This field is required",
                          "showInputLabel": true,
                          "labelContent": "",
                          "labelClass": "form-label",
                          "isLabelAfter": false,
                          "showAdditionalInputContent": false,
                          "additionalInputContent": "",
                          "isAdditonalInputAfter": true,
                          "draggable": false
                        }
                      ],
                      "name": "box_644",
                      "id": "box_716",
                      "wrapperType": "BOX",
                      "fields": [],
                      "classname": "",
                      "addButton": {
                        "classname": "",
                        "value": ""
                      },
                      "removeButton": {
                        "classname": "",
                        "value": ""
                      },
                      "wrapperClassName": ""
                    },
                    "classname": ""
                  }
                ]
              }
            }
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "head": "",
        "name": "main",
        "id": "main",
        "wrapperType": "REGULAR",
        "fields": [
          {
            "control": [
              {
                "type": "label",
                "name": "Bacterial Strain and Amplification Info",
                "classname": "",
                "for": "",
                "required": false,
                "wrapperClassName": "",
                "id": "label_198",
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_391",
            "classname": "add-heading heading-without-icon",
            "wrapperType": "BOX",
            "id": "wrapper_108",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          }
        ],
        "type": "",
        "classname": "col-md-12"
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_133",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_329",
        "fields": [
          {
            "control": [
              {
                "type": "dropdown",
                "name": "bacterial_strain",
                "id": "bacterial_strain",
                "validationButtonType": "BSP-submit",
                "classname": "",
                "required": true,
                "apiEndPoints": [
                  {
                    "type": "init",
                    "method": "post",
                    "url": "inventory_item",
                    "payload": {
                      "user_input": "",
                      "perma": "bacterial-strains"
                    }
                  }
                ],
                "options": [],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": true,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showBlankInPlaceholder": false,
                "blankDataList": [],
                "showInputLabel": true,
                "labelContent": "Bacterial Strain",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_47",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_576",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "dropdown",
                "name": "cell_cult_media",
                "id": "cell_cult_media",
                "validationButtonType": "BSP-submit",
                "classname": "",
                "required": true,
                "apiEndPoints": [
                  {
                    "type": "init",
                    "method": "post",
                    "url": "inventory_item",
                    "payload": {
                      "user_input": "",
                      "perma": "cell-culture-media"
                    }
                  }
                ],
                "options": [],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": true,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showBlankInPlaceholder": false,
                "blankDataList": [],
                "showInputLabel": true,
                "labelContent": "Media",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_686",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_396",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "incubation_time",
                "id": "incubation_time",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "BSP-submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": true,
                "customValidation": [
                  {
                    "regularExpression": "/^d+(.d+)?$/",
                    "errorMessage": "Please type a number"
                  }
                ],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Incubation Time(h)",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_865",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_166",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "temperature",
                "id": "temperature",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "BSP-submit",
                "rows": 0,
                "customValidation": [
                  {
                    "regularExpression": "/^-?d+(.d+)?$/",
                    "errorMessage": "Please type a number"
                  }
                ],
                "columns": 0,
                "onDemandError": true,
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Temperature(°C)",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_179",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_547",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "experimental_notes",
                "id": "experimental_notes",
                "value": "",
                "placeholder": "",
                "multiline": true,
                "classname": "form-control textarea",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Experimental Notes/Comments",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_588",
            "classname": "col-12",
            "wrapperType": "BOX",
            "id": "wrapper_345",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      }
    ],
    "type": "",
    "classname": ""
  },

//bacterial stock creation step in project
{
  "jsonId":54,
  "control":[
     
  ],
  "head":"",
  "name":"main",
  "id":"main",
  "wrapperType":"REGULAR",
  "fields":[
     {
        "control":[
           
        ],
        "type":"",
        "name":"wrapper_871",
        "classname":"row",
        "wrapperType":"BOX",
        "id":"wrapper_17",
        "fields":[
           {
              "control":[
                 {
                    "type":"dropdown",
                    "name":"protocol",
                    "id":"protocol",
                    "validationButtonType":"BSC-submit",
                    "classname":"",
                    "required":true,
                    "apiEndPoints":[
                       {
                          "type":"init",
                          "method":"get",
                          "url":"protocol/protocol-list"
                       },
                       {
                          "type":"change",
                          "method":"get",
                          "Mapto":[
                             {
                                "id":"",
                                "displayKey":"value",
                                "urlParameterValues":{
                                   "id":null
                                }
                             }
                          ],
                          "url":"protocol/${id}"
                       }
                    ],
                    "options":[
                       
                    ],
                    "multiple":false,
                    "isClearable":false,
                    "isStatus":false,
                    "search":false,
                    "singleSelect":true,
                    "showLabel":"value",
                    "errorMessage":{
                       "noData":"This field is required"
                    },
                    "value":"",
                    "imageUrl":"",
                    "disabled":false,
                    "wrapperClassName":"",
                    "connectedArrays":[
                       
                    ],
                    "showBlankInPlaceholder":false,
                    "blankDataList":[
                       
                    ],
                    "showInputLabel":true,
                    "labelContent":"Protocol",
                    "labelClass":"form-label",
                    "isLabelAfter":false,
                    "showAdditionalInputContent":false,
                    "additionalInputContent":"",
                    "isAdditonalInputAfter":true,
                    "draggable":false
                 }
              ],
              "type":"",
              "name":"wrapper_392",
              "classname":"col-3 mb-3",
              "wrapperType":"BOX",
              "id":"wrapper_650",
              "fields":[
                 
              ],
              "head":"",
              "tabHeadClass":""
           },
           {
              "control":[
                 
              ],
              "type":"",
              "name":"wrapper_916",
              "classname":"col-md-3 mt-4",
              "wrapperType":"BOX",
              "id":"wrapper_62",
              "fields":[
                 {
                    "control":[
                       {
                          "type":"button",
                          "id":"view",
                          "value":"<div class=\"custom-tooltip\"><p>View </p></div>",
                          "buttonType":"viewProtocol",
                          "classname":"view",
                          "divClass":"",
                          "controlEndPoints":[
                             
                          ],
                          "disabled":false,
                          "validationEndPoints":[
                             
                          ],
                          "invalid":false,
                          "name":"view",
                          "wrapperClassName":"",
                          "draggable":false
                       }
                    ],
                    "type":"",
                    "name":"wrapper_913",
                    "classname":"actions",
                    "wrapperType":"BOX",
                    "id":"wrapper_113",
                    "fields":[
                       
                    ],
                    "head":"",
                    "tabHeadClass":"",
                    "draggable":false
                 }
              ],
              "head":"",
              "tabHeadClass":"",
              "draggable":false
           },
           {
              "control":[
                 
              ],
              "head":"",
              "name":"main",
              "id":"main",
              "wrapperType":"REGULAR",
              "fields":[
                 {
                    "control":[
                       {
                          "type":"label",
                          "name":"Bacterial Stocks Info",
                          "classname":"",
                          "for":"",
                          "required":false,
                          "wrapperClassName":"",
                          "id":"label_198",
                          "draggable":false
                       }
                    ],
                    "type":"",
                    "name":"wrapper_391",
                    "classname":"add-heading heading-without-icon",
                    "wrapperType":"BOX",
                    "id":"wrapper_108",
                    "fields":[
                       
                    ],
                    "head":"",
                    "tabHeadClass":"",
                    "draggable":false
                 }
              ],
              "type":"",
              "classname":"col-md-12"
           }
        ],
        "head":"",
        "tabHeadClass":"",
        "draggable":false
     },
     {
        "control":[
           
        ],
        "type":"",
        "name":"wrapper_73",
        "classname":"bacterial-stock-table",
        "wrapperType":"BOX",
        "id":"wrapper_364",
        "fields":[
           {
              "control":[
                 
              ],
              "type":"",
              "name":"bacterial_stock_info",
              "classname":"table",
              "wrapperType":"TABLE",
              "id":"bacterial_stock_info",
              "fields":[
                 
              ],
              "head":"",
              "tabHeadClass":"",
              "draggable":false,
              "tableFields":{
                 "showHead":true,
                 "isVertical":false,
                 "isIteratable":false,
                 "addButton":{
                    "classname":"btn btn-primary",
                    "name":"",
                    "value":""
                 },
                 "removeButton":{
                    "classname":"btn btn-danger",
                    "name":"",
                    "value":""
                 },
                 "tableHead":{
                    "heading":[
                       {
                          "value":"BACTERIAL STRAIN",
                          "classname":""
                       },
                       {
                          "value":"PLASMID NAME",
                          "classname":""
                       },
                       {
                          "value":"PLASMID ID",
                          "classname":""
                       },
                       {
                          "value":"STOCK ID",
                          "classname":""
                       },
                       {
                          "value":"GLYCEROL %",
                          "classname":""
                       },
                       {
                          "value":"TUBETYPE",
                          "classname":""
                       },
                       {
                          "value":"NO OF VIALS",
                          "classname":""
                       },
                       {
                          "value":"VOL PER VIAL <span>(μl)</span>",
                          "classname":""
                       },
                       {
                          "value":"TOTAL VOL <span>(μl)</span>",
                          "classname":""
                       }
                    ],
                    "classname":""
                 },
                 "initRecordCount":1,
                 "showRecordCount":false,
                 "recordCounterConfig":{
                    "classname":"",
                    "adjValue":"",
                    "isAdjValueAfter":false
                 },
                 "tableRow":{
                    "name":"",
                    "classname":"trclass",
                    "tableCols":[
                       {
                          "fields":{
                             "control":[
                                {
                                   "type":"text",
                                   "name":"bacterial_resistance",
                                   "id":"bacterial_resistance",
                                   "value":"",
                                   "placeholder":"",
                                   "multiline":false,
                                   "classname":"form-control ",
                                   "regularExpression":"",
                                   "disabled":true,
                                   "required":false,
                                   "errorMessage":{
                                      "noData":"This field is required",
                                      "regexFail":""
                                   },
                                   "validationButtonType":"BSC-submit",
                                   "rows":0,
                                   "columns":0,
                                   "onDemandError":false,
                                   "customValidation":[
                                      
                                   ],
                                   "multiError":false,
                                   "applicationError":"",
                                   "wrapperClassName":"",
                                   "requiredErrorMessage":"This field is required",
                                   "showInputLabel":false,
                                   "labelContent":"",
                                   "labelClass":"form-label",
                                   "isLabelAfter":false,
                                   "showAdditionalInputContent":false,
                                   "additionalInputContent":"",
                                   "isAdditonalInputAfter":true,
                                   "draggable":false
                                }
                             ],
                             "head":"",
                             "name":"box_734",
                             "id":"box_582",
                             "wrapperType":"BOX",
                             "fields":[
                                
                             ],
                             "type":"",
                             "classname":""
                          },
                          "classname":""
                       },
                       {
                          "fields":{
                             "control":[
                                {
                                   "type":"text",
                                   "name":"plasmid",
                                   "id":"plasmid",
                                   "value":"",
                                   "placeholder":"",
                                   "multiline":false,
                                   "classname":"form-control ",
                                   "regularExpression":"",
                                   "disabled":true,
                                   "required":false,
                                   "errorMessage":{
                                      "noData":"This field is required",
                                      "regexFail":""
                                   },
                                   "validationButtonType":"BSC-submit",
                                   "rows":0,
                                   "columns":0,
                                   "onDemandError":false,
                                   "customValidation":[
                                      
                                   ],
                                   "multiError":false,
                                   "applicationError":"",
                                   "wrapperClassName":"",
                                   "requiredErrorMessage":"This field is required",
                                   "showInputLabel":false,
                                   "labelContent":"",
                                   "labelClass":"form-label",
                                   "isLabelAfter":false,
                                   "showAdditionalInputContent":false,
                                   "additionalInputContent":"",
                                   "isAdditonalInputAfter":true,
                                   "draggable":false
                                },
                                {
                                   "type":"text",
                                   "name":"inventory_item_id",
                                   "id":"inventory_item_id",
                                   "value":"",
                                   "placeholder":"",
                                   "multiline":false,
                                   "classname":"form-control d-none",
                                   "regularExpression":"",
                                   "disabled":true,
                                   "required":false,
                                   "errorMessage":{
                                      "noData":"This field is required",
                                      "regexFail":""
                                   },
                                   "validationButtonType":"BSC-submit",
                                   "rows":0,
                                   "columns":0,
                                   "onDemandError":false,
                                   "customValidation":[
                                      
                                   ],
                                   "multiError":false,
                                   "applicationError":"",
                                   "wrapperClassName":"",
                                   "requiredErrorMessage":"This field is required",
                                   "showInputLabel":false,
                                   "labelContent":"",
                                   "labelClass":"form-label",
                                   "isLabelAfter":false,
                                   "showAdditionalInputContent":false,
                                   "additionalInputContent":"",
                                   "isAdditonalInputAfter":true,
                                   "draggable":false
                                }
                             ],
                             "name":"box_999",
                             "id":"box_936",
                             "wrapperType":"BOX",
                             "fields":[
                                
                             ],
                             "type":"",
                             "classname":"",
                             "wrapperClassName":""
                          },
                          "classname":""
                       },
                       {
                          "fields":{
                             "control":[
                                {
                                   "type":"text",
                                   "name":"inv_item_code",
                                   "id":"inv_item_code",
                                   "value":"",
                                   "placeholder":"",
                                   "multiline":false,
                                   "classname":"form-control ",
                                   "regularExpression":"",
                                   "disabled":true,
                                   "required":false,
                                   "errorMessage":{
                                      "noData":"This field is required",
                                      "regexFail":""
                                   },
                                   "validationButtonType":"BSC-submit",
                                   "rows":0,
                                   "columns":0,
                                   "onDemandError":false,
                                   "customValidation":[
                                      
                                   ],
                                   "multiError":false,
                                   "applicationError":"",
                                   "wrapperClassName":"",
                                   "requiredErrorMessage":"This field is required",
                                   "showInputLabel":false,
                                   "labelContent":"",
                                   "labelClass":"form-label",
                                   "isLabelAfter":false,
                                   "showAdditionalInputContent":false,
                                   "additionalInputContent":"",
                                   "isAdditonalInputAfter":true,
                                   "draggable":false
                                },
                                {
                                   "type":"text",
                                   "name":"project_plasmid_con_id",
                                   "id":"project_plasmid_con_id",
                                   "value":"",
                                   "placeholder":"",
                                   "multiline":false,
                                   "classname":"form-control d-none",
                                   "regularExpression":"",
                                   "disabled":true,
                                   "required":false,
                                   "errorMessage":{
                                      "noData":"This field is required",
                                      "regexFail":""
                                   },
                                   "validationButtonType":"BSC-submit",
                                   "rows":0,
                                   "columns":0,
                                   "onDemandError":false,
                                   "customValidation":[
                                      
                                   ],
                                   "multiError":false,
                                   "applicationError":"",
                                   "wrapperClassName":"",
                                   "requiredErrorMessage":"This field is required",
                                   "showInputLabel":false,
                                   "labelContent":"",
                                   "labelClass":"form-label",
                                   "isLabelAfter":false,
                                   "showAdditionalInputContent":false,
                                   "additionalInputContent":"",
                                   "isAdditonalInputAfter":true,
                                   "draggable":false
                                }
                             ],
                             "name":"box_803",
                             "id":"box_433",
                             "wrapperType":"BOX",
                             "fields":[
                                
                             ],
                             "type":"",
                             "classname":"",
                             "wrapperClassName":""
                          },
                          "classname":""
                       },
                       {
                          "fields":{
                             "control":[
                                {
                                   "type":"text",
                                   "name":"stock_id",
                                   "id":"stock_id",
                                   "value":"",
                                   "placeholder":"",
                                   "multiline":false,
                                   "classname":"form-control ",
                                   "regularExpression":"",
                                   "disabled":true,
                                   "required":false,
                                   "errorMessage":{
                                      "noData":"This field is required",
                                      "regexFail":""
                                   },
                                   "validationButtonType":"BSC-submit",
                                   "rows":0,
                                   "columns":0,
                                   "onDemandError":false,
                                   "customValidation":[
                                      
                                   ],
                                   "multiError":false,
                                   "applicationError":"",
                                   "wrapperClassName":"",
                                   "requiredErrorMessage":"This field is required",
                                   "showInputLabel":false,
                                   "labelContent":"",
                                   "labelClass":"form-label",
                                   "isLabelAfter":false,
                                   "showAdditionalInputContent":false,
                                   "additionalInputContent":"",
                                   "isAdditonalInputAfter":true,
                                   "draggable":false
                                }
                             ],
                             "name":"box_298",
                             "id":"box_607",
                             "wrapperType":"BOX",
                             "fields":[
                                
                             ],
                             "type":"",
                             "classname":"",
                             "wrapperClassName":""
                          },
                          "classname":""
                       },
                       {
                          "fields":{
                             "control":[
                                {
                                   "type":"dropdown",
                                   "name":"glycerol_per",
                                   "id":"glycerol_per",
                                   "validationButtonType":"BSC-submit",
                                   "classname":"",
                                   "required":false,
                                   "apiEndPoints":[
                                      
                                   ],
                                   "options":[
                                      {
                                         "id":1,
                                         "value":"50%"
                                      }
                                   ],
                                   "multiple":false,
                                   "isClearable":false,
                                   "isStatus":false,
                                   "search":false,
                                   "singleSelect":true,
                                   "showLabel":"value",
                                   "errorMessage":{
                                      "noData":"This field is required"
                                   },
                                   "value":{
                                      "id":1,
                                      "value":"50%"
                                   },
                                   "imageUrl":"",
                                   "disabled":false,
                                   "wrapperClassName":"",
                                   "connectedArrays":[
                                      
                                   ],
                                   "showBlankInPlaceholder":false,
                                   "blankDataList":[
                                      
                                   ],
                                   "showInputLabel":false,
                                   "labelContent":"",
                                   "labelClass":"form-label d-none",
                                   "isLabelAfter":false,
                                   "showAdditionalInputContent":false,
                                   "additionalInputContent":"",
                                   "isAdditonalInputAfter":true,
                                   "draggable":false
                                }
                             ],
                             "name":"box_97",
                             "id":"box_195",
                             "wrapperType":"BOX",
                             "fields":[
                                
                             ],
                             "type":"",
                             "classname":"",
                             "wrapperClassName":""
                          },
                          "classname":""
                       },
                       {
                          "fields":{
                             "control":[
                                {
                                   "type":"dropdown",
                                   "name":"tube_type",
                                   "id":"tube_type",
                                   "validationButtonType":"BSC-submit",
                                   "classname":"",
                                   "required":true,
                                   "apiEndPoints":[
                                      
                                   ],
                                   "options":[
                                      {
                                         "id":1,
                                         "value":"Cryovials"
                                      },
                                      {
                                         "id":2,
                                         "value":"Screw Cap Tube"
                                      }
                                   ],
                                   "multiple":false,
                                   "isClearable":false,
                                   "isStatus":false,
                                   "search":false,
                                   "singleSelect":true,
                                   "showLabel":"value",
                                   "errorMessage":{
                                      "noData":"This field is required"
                                   },
                                   "value":"",
                                   "imageUrl":"",
                                   "disabled":false,
                                   "wrapperClassName":"",
                                   "connectedArrays":[
                                      
                                   ],
                                   "showBlankInPlaceholder":false,
                                   "blankDataList":[
                                      
                                   ],
                                   "showInputLabel":true,
                                   "labelContent":"Plasmid1",
                                   "labelClass":"form-label d-none",
                                   "isLabelAfter":false,
                                   "showAdditionalInputContent":false,
                                   "additionalInputContent":"",
                                   "isAdditonalInputAfter":true,
                                   "draggable":false
                                }
                             ],
                             "name":"box_778",
                             "id":"box_410",
                             "wrapperType":"BOX",
                             "fields":[
                                
                             ],
                             "type":"",
                             "classname":"",
                             "wrapperClassName":""
                          },
                          "classname":""
                       },
                       {
                          "fields":{
                             "control":[
                                {
                                   "type":"text",
                                   "name":"no_of_vials",
                                   "id":"no_of_vials",
                                   "value":"",
                                   "placeholder":"",
                                   "multiline":false,
                                   "classname":"form-control ",
                                   "regularExpression":"",
                                   "disabled":false,
                                   "required":true,
                                   "errorMessage":{
                                      "noData":"This field is required",
                                      "regexFail":""
                                   },
                                   "validationButtonType":"BSC-submit",
                                   "rows":0,
                                   "columns":0,
                                   "onDemandError":true,
                                   "customValidation":[
                                      {
                                         "regularExpression":"/^[1-9][0-9]*$/",
                                         "errorMessage":"Please type a valid non zero integer"
                                      }
                                   ],
                                   "multiError":false,
                                   "applicationError":"",
                                   "wrapperClassName":"",
                                   "requiredErrorMessage":"This field is required",
                                   "showInputLabel":false,
                                   "labelContent":"",
                                   "labelClass":"form-label d-none",
                                   "isLabelAfter":false,
                                   "showAdditionalInputContent":false,
                                   "additionalInputContent":"",
                                   "isAdditonalInputAfter":true,
                                   "draggable":false
                                }
                             ],
                             "name":"box_855",
                             "id":"box_387",
                             "wrapperType":"BOX",
                             "fields":[
                                
                             ],
                             "type":"",
                             "classname":"",
                             "wrapperClassName":""
                          },
                          "classname":""
                       },
                       {
                          "fields":{
                             "control":[
                                {
                                   "type":"text",
                                   "name":"vol_per_vial",
                                   "id":"vol_per_vial",
                                   "value":"",
                                   "placeholder":"",
                                   "multiline":false,
                                   "classname":"form-control ",
                                   "regularExpression":"",
                                   "disabled":false,
                                   "required":true,
                                   "errorMessage":{
                                      "noData":"This field is required",
                                      "regexFail":""
                                   },
                                   "validationButtonType":"BSC-submit",
                                   "rows":0,
                                   "columns":0,
                                   "onDemandError":true,
                                   "customValidation":[
                                      {
                                         "regularExpression":"/^(0\\.[1-9]+|[1-9]\\d*(\\.\\d+)?)$/",
                                         "errorMessage":"Please type a valid number"
                                      }
                                   ],
                                   "multiError":false,
                                   "applicationError":"",
                                   "wrapperClassName":"",
                                   "requiredErrorMessage":"This field is required",
                                   "showInputLabel":false,
                                   "labelContent":"",
                                   "labelClass":"form-label d-none",
                                   "isLabelAfter":false,
                                   "showAdditionalInputContent":false,
                                   "additionalInputContent":"",
                                   "isAdditonalInputAfter":true,
                                   "draggable":false
                                }
                             ],
                             "name":"box_155",
                             "id":"box_13",
                             "wrapperType":"BOX",
                             "fields":[
                                
                             ],
                             "type":"",
                             "classname":"",
                             "wrapperClassName":""
                          },
                          "classname":""
                       },
                       {
                          "fields":{
                             "control":[
                                {
                                   "type":"text",
                                   "name":"total_vol",
                                   "id":"total_vol",
                                   "value":"",
                                   "placeholder":"",
                                   "multiline":false,
                                   "classname":"form-control ",
                                   "regularExpression":"",
                                   "disabled":true,
                                   "required":false,
                                   "errorMessage":{
                                      "noData":"This field is required",
                                      "regexFail":""
                                   },
                                   "validationButtonType":"BSC-submit",
                                   "rows":0,
                                   "columns":0,
                                   "onDemandError":false,
                                   "customValidation":[
                                      
                                   ],
                                   "multiError":false,
                                   "applicationError":"",
                                   "wrapperClassName":"",
                                   "requiredErrorMessage":"This field is required",
                                   "showInputLabel":false,
                                   "labelContent":"",
                                   "labelClass":"form-label d-none",
                                   "isLabelAfter":false,
                                   "showAdditionalInputContent":false,
                                   "additionalInputContent":"",
                                   "isAdditonalInputAfter":true,
                                   "draggable":false
                                }
                             ],
                             "name":"box_856",
                             "id":"box_101",
                             "wrapperType":"BOX",
                             "fields":[
                                
                             ],
                             "type":"",
                             "classname":"",
                             "wrapperClassName":""
                          },
                          "classname":""
                       }
                    ]
                 }
              }
           }
        ],
        "head":"",
        "tabHeadClass":"",
        "draggable":false
     }
  ],
  "type":"",
  "classname":""
}]