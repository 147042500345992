import { CURRENTDATE } from "../app-constants";

export const ProjectappDynamicJSON = [
// PROJECT INITIATION step in antibody production and purification
{
    jsonId: 71,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_576',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_790',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'project_title',
                id: 'project_title',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'initial-submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Project Title',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_50',
            classname: 'col-12 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_808',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'project_init_by',
                id: 'project_init_by',
                validationButtonType: 'initial-submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'get',
                    url: 'user_management/users_list',
                  },
                ],
                // options: [
                //   {
                //     id: 1,
                //     value: 'admin',
                //   },
                // ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: true,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Initiated By',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_290',
            classname: 'col-12 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_654',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'project_start_date',
                id: 'project_start_date',
                validationButtonType: 'initial-submit',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: true,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: '',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Start Date',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_209',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_778',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'project_end_date',
                id: 'project_end_date',
                validationButtonType: '',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                required: false,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: '',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'End Date',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_643',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_92',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: 'form-container',
  },

  // PLASMID INFORMATION step in antibody production and purification
  {
    jsonId: 72,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_871',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_17',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'antibody_name',
                id: 'antibody_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'plasmid-submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Antibody Name',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_50',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_808',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'antibody_id',
                id: 'antibody_id',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: true,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'plasmid-submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Antibody ID',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_50',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_808',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_73',
        classname: 'project-table',
        wrapperType: 'BOX',
        id: 'wrapper_364',
        fields: [
          {
            control: [],
            type: '',
            name: 'plasmidtable',
            classname: 'table project-ini-table',
            wrapperType: 'TABLE',
            id: 'plasmidtable',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            tableFields: {
              showHead: true,
              isVertical: false,
              isIteratable: true,
              addButton: {
                classname: 'add-expand',
                name: '',
                value: '',
              },
              removeButton: {
                classname: 'add-minus',
                name: '',
                value: '',
              },
              tableHead: {
                heading: [
                  { value: 'PLASMID TYPE', classname: '' },
                  { value: 'PLASMID', classname: '' },
                  { value: 'PLASMID ID', classname: '' },
                  { value: 'SOURCE', classname: '' },
                  { value: 'ANTIBIOTIC RESISTANCE', classname: '' },
                  { value: 'STORAGE LOCATION', classname: '' },
                  { value: 'ACTIONS', classname: '' },
                  { value: '', classname: '' },
                ],
                classname: '',
              },
              initRecordCount: 1,
              showRecordCount: false,
              recordCounterConfig: { classname: '', adjValue: '', isAdjValueAfter: false },
              tableRow: {
                name: '',
                classname: 'trclass',
                tableCols: [
                  {
                    fields: {
                      control: [
                        {
                          type: 'dropdown',
                          name: 'plasmid_type',
                          id: 'plasmid_type',
                          validationButtonType: 'plasmid-submit',
                          classname: '',
                          required: true,
                          apiEndPoints: [
                            {
                              type: 'init',
                              method: 'post',
                              url: 'inventory-plasmids/inventory-sub-types',
                              payload: {
                                perma: ['plasmids'],
                              },
                            },
                            {
                              type: 'change',
                              method: 'get',
                              Mapto: [
                                {
                                  id: 'main.wrapper_364.plasmidtable.${i}.Group_Details.plasmid',
                                  displayKey: 'value',
                                  urlParameterValues: { id: null },
                                },
                              ],
                              url: 'projects/antibody_production/${id}',
                            },
                          ],
                          options: [],
                          multiple: false,
                          isClearable: false,
                          isStatus: false,
                          search: true,
                          singleSelect: true,
                          showLabel: 'value',
                          errorMessage: { noData: 'This field is required' },
                          value: '',
                          imageUrl: '',
                          disabled: false,
                          wrapperClassName: '',
                          connectedArrays: [],
                          showBlankInPlaceholder: false,
                          blankDataList: [],
                          showInputLabel: true,
                          labelContent: '',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      head: '',
                      name: 'box_734',
                      id: 'box_582',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                    },
                    classname: '',
                  },

                  {
                    fields: {
                      control: [
                        {
                          required: true,
                          validationButtonType: 'plasmid-submit',
                          type: 'dropdown',
                          name: 'plasmid',
                          id: 'plasmid',
                          classname: '',
                          apiEndPoints: [
                            {
                              type: 'change',
                              method: 'get',
                              isGet: true,
                              Mapto: [
                                {
                                  id: 'main.wrapper_364.plasmidtable.${i}.box_716.plasmid_id',
                                  displayKey: 'change',
                                  urlParameterValues: { inv_id: null, patchProperty: 'plasmids_id' },
                                },
                                {
                                  id: 'main.wrapper_364.plasmidtable.${i}.box_716.source',
                                  displayKey: 'change',
                                  urlParameterValues: { inv_id: null, patchProperty: 'source' },
                                },
                                {
                                  id: 'main.wrapper_364.plasmidtable.${i}.box_716.antibiotic_resistence',
                                  displayKey: 'change',
                                  urlParameterValues: { inv_id: null, patchProperty: 'bacterial_resistance' },
                                },
                                {
                                  id: 'main.wrapper_364.plasmidtable.${i}.box_716.plasmid_info',
                                  displayKey: 'change',
                                  urlParameterValues: { inv_id: null, patchProperty: 'plasmid_info' },
                                },
                              ],
                              url: 'inventory-plasmids/${inv_id}',
                            },
                          ],
                          options: [],
                          multiple: false,
                          isClearable: true,
                          isStatus: false,
                          search: true,
                          singleSelect: true,
                          showLabel: 'value',
                          disabled: false,
                          wrapperClassName: '',
                          connectedArrays: [],
                          showBlankInPlaceholder: false,
                          blankDataList: [],
                          showInputLabel: true,
                          labelContent: '',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                          errorMessage: {
                            noData: 'This field is required',
                          },
                        },
                        {
                          type: 'button',
                          id: 'infoicon',
                          value: '',
                          buttonType: 'plasmid_info',
                          classname: 'info',
                          divClass: '',
                          controlEndPoints: [],
                          disabled: false,
                          validationEndPoints: [],
                          invalid: false,
                          name: 'infoicon',
                          wrapperClassName: '',
                          draggable: false,
                        },
                      ],
                      name: 'groupDetails',
                      classname: ['action-wrap'],
                      id: 'Group_Details',
                      wrapperType: 'BOX',
                      fields: [],
                    },
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'plasmid_id',
                          id: 'plasmid_id',
                          value: '-',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: {
                            noData: 'This field is required',
                            regexFail: '',
                          },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: '',
                          labelClass: 'form-label',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                        {
                          type: 'text',
                          name: 'plasmid_info',
                          id: 'plasmid_info',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'd-none',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: {
                            noData: 'This field is required',
                            regexFail: '',
                          },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: '',
                          labelClass: 'd-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],

                      name: 'box_644',
                      id: 'box_716',
                      wrapperType: 'BOX',
                      fields: [],
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },

                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'source',
                          id: 'source',
                          value: '-',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: {
                            noData: 'This field is required',
                            regexFail: '',
                          },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: '',
                          labelClass: 'form-label',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_644',
                      id: 'box_716',
                      wrapperType: 'BOX',
                      fields: [],
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'antibiotic_resistence',
                          id: 'antibiotic_resistence',
                          value: '-',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: {
                            noData: 'This field is required',
                            regexFail: '',
                          },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: '',
                          labelClass: 'form-label',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_644',
                      id: 'box_716',
                      wrapperType: 'BOX',
                      fields: [],
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'button',
                          id: 'infoicon',
                          value: 'View Location',
                          buttonType: 'storage_info',
                          classname: 'info-icon',
                          divClass: '',
                          controlEndPoints: [],
                          disabled: false,
                          validationEndPoints: [],
                          invalid: false,
                          name: 'infoicon',
                          wrapperClassName: '',
                          draggable: false,
                        },
                      ],
                      name: 'box_644',
                      id: 'box_716',
                      wrapperType: 'BOX',
                      fields: [],
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'button',
                          id: 'plasmidmap',
                          value: '<div class="custom-tooltip"><p>View Plasmid Map</p></div>',
                          buttonType: 'plasmid_map',
                          classname: 'plasmid-view',
                          divClass: '',
                          controlEndPoints: [],
                          disabled: false,
                          validationEndPoints: [],
                          invalid: false,
                          name: 'plasmidmap',
                          wrapperClassName: '',
                          draggable: false,
                        },
                      ],
                      name: 'box_985',
                      id: 'box_89',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: 'actions',
                      addButton: { classname: '', value: '<div class="custom-tooltip"><p>Add Row</div>' },
                      removeButton: { classname: '', value: '<div class="custom-tooltip"><p>Remove Row</div>' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                ],
              },
            },
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_133',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_329',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'exprmntal_notes',
                id: 'exprmntal_notes',
                value: '',
                placeholder: '',
                multiline: true,
                classname: 'form-control textarea',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Experimental Notes/Comments',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_588',
            classname: 'col-12 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_345',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //Antibody Production & Purification WORKFLOW Plasmid Transfection
  {
    "jsonId": 76,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
      {
        "control": [],
        "type": "",
        "name": "wrapper_871",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_17",
        "fields": [
          {
            "control": [
              {
               type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "protocol",
                "id": "protocol",
                "validationButtonType": "ABP-Submit",
                "classname": "",
                "required": true,
                "apiEndPoints": [
                  {
                    "type": "init",
                    "method": "get",
                    "url": "protocol/protocol-list"
                  },
                  {
                    "type": "change",
                    "method": "get",
                    "Mapto": [
                      {
                        "id": "",
                        "displayKey": "value",
                        "urlParameterValues": {
                          "id": null
                        }
                      }
                    ],
                    "url": "protocol/${id}"
                  }
                ],
                "options": [],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": true,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showInputLabel": true,
                "labelContent": "Protocol",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_392",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_650",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
                "type": "button",
                "id": "protocol_info",
                "value": "<div class=\"custom-tooltip\"><p>View</p></div>",
                "buttonType": "protocol_info",
                "classname": "view",
                "divClass": "",
                "controlEndPoints": [],
                "disabled": false,
                "validationEndPoints": [],
                "invalid": false,
                "name": "button_779",
                "wrapperClassName": "actions position-relative",
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_861",
            "classname": "col-1 mt-4 pt-2",
            "wrapperType": "BOX",
            "id": "wrapper_41",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_983",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_156",
        "fields": [
          {
            "control": [
              {
                "type": "label",
                "name": "Plasmid Info",
                "classname": "",
                "for": "",
                "required": false,
                "wrapperClassName": "",
                "id": "label_432",
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_95",
            "classname": "col-12  add-heading heading-without-icon ",
            "wrapperType": "BOX",
            "id": "wrapper_276",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_73",
        "classname": "plasmid-table",
        "wrapperType": "BOX",
        "id": "wrapper_364",
        "fields": [
          {
            "control": [],
            "type": "",
            "name": "plasmidtable",
            "classname": "table",
            "wrapperType": "TABLE",
            "id": "plasmidtable",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false,
            "tableFields": {
              "showHead": true,
              "isVertical": false,
              "isIteratable": false,
              "addButton": {
                "classname": "btn btn-primary",
                "name": "",
                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo,\nwww.svgrepo.com\n, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"\nhttp://www.w3.org/2000/svg\">\n<circle\ncx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
              },
              "removeButton": {
                "classname": "btn btn-danger",
                "name": "",
                "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo,\nwww.svgrepo.com\n, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"\nhttp://www.w3.org/2000/svg\">\n<path\nd=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
              },
              "tableHead": {
                "heading": [
                  {
                    "value": "PLASMID TYPE",
                    "classname": ""
                  },
                  {
                    "value": "PLASMID",
                    "classname": ""
                  },
                  {
                    "value": "PLASMID ID",
                    "classname": ""
                  },
                  {
                    "value": "Antibiotic Resistance",
                    "classname": ""
                  },
                  {
                    "value": "Mammalian Resistance",
                    "classname": ""
                  }
                ],
                "classname": ""
              },
              "initRecordCount": 1,
              "showRecordCount": false,
              "recordCounterConfig": {
                "classname": "",
                "adjValue": "",
                "isAdjValueAfter": false
              },
              "tableRow": {
                "name": "",
                "classname": "trclass",
                "tableCols": [
                  {
                    "fields": {
                      "control": [
                        {
                          "type": "text",
                          "name": "plasmid_type",
                          "id": "plasmid_type",
                          "value": "",
                          "placeholder": "",
                          "multiline": false,
                          "classname": "form-control ",
                          "regularExpression": "",
                          "disabled": true,
                          "required": false,
                          "errorMessage": {
                            "noData": "This field is required",
                            "regexFail": ""
                          },
                          "validationButtonType": "",
                          "rows": 0,
                          "columns": 0,
                          "onDemandError": false,
                          "customValidation": [],
                          "multiError": false,
                          "applicationError": "",
                          "wrapperClassName": "",
                          "requiredErrorMessage": "This field is required",
                          "showInputLabel": true,
                          "labelContent": "text_232",
                          "labelClass": "form-label d-none",
                          "isLabelAfter": false,
                          "showAdditionalInputContent": false,
                          "additionalInputContent": "",
                          "isAdditonalInputAfter": true,
                          "draggable": false
                        }
                      ],
                      "head": "",
                      "name": "box_734",
                      "id": "box_582",
                      "wrapperType": "BOX",
                      "fields": [],
                      "type": "",
                      "classname": ""
                    },
                    "classname": ""
                  },
                  {
                    "fields": {
                      "control": [
                        {
                          "type": "text",
                          "name": "plasmid",
                          "id": "plasmid",
                          "value": "",
                          "placeholder": "",
                          "multiline": false,
                          "classname": "form-control ",
                          "regularExpression": "",
                          "disabled": true,
                          "required": false,
                          "errorMessage": {
                            "noData": "This field is required",
                            "regexFail": ""
                          },
                          "validationButtonType": "",
                          "rows": 0,
                          "columns": 0,
                          "onDemandError": false,
                          "customValidation": [],
                          "multiError": false,
                          "applicationError": "",
                          "wrapperClassName": "",
                          "requiredErrorMessage": "This field is required",
                          "showInputLabel": true,
                          "labelContent": "text_232",
                          "labelClass": "form-label d-none",
                          "isLabelAfter": false,
                          "showAdditionalInputContent": false,
                          "additionalInputContent": "",
                          "isAdditonalInputAfter": true,
                          "draggable": false
                        },
                        {
                          "type": "button",
                          "id": "button_693",
                          "value": "",
                          "buttonType": "plasmid_info",
                          "classname": "info",
                          "divClass": "",
                          "controlEndPoints": [],
                          "disabled": false,
                          "validationEndPoints": [],
                          "invalid": false,
                          "name": "button_54",
                          "wrapperClassName": "",
                          "draggable": false
                        }
                      ],
                      "name": "box_999",
                      "id": "box_936",
                      "wrapperType": "BOX",
                      "fields": [],
                      "type": "",
                      "classname": "action-wrap",
                      "addButton": {
                        "classname": "",
                        "value": ""
                      },
                      "removeButton": {
                        "classname": "",
                        "value": ""
                      },
                      "wrapperClassName": ""
                    },
                    "classname": ""
                  },
                  {
                    "fields": {
                      "control": [
                        {
                          "type": "text",
                          "name": "plasmid_id",
                          "id": "plasmid_id",
                          "value": "",
                          "placeholder": "",
                          "multiline": false,
                          "classname": "form-control ",
                          "regularExpression": "",
                          "disabled": true,
                          "required": false,
                          "errorMessage": {
                            "noData": "This field is required",
                            "regexFail": ""
                          },
                          "validationButtonType": "",
                          "rows": 0,
                          "columns": 0,
                          "onDemandError": false,
                          "customValidation": [],
                          "multiError": false,
                          "applicationError": "",
                          "wrapperClassName": "",
                          "requiredErrorMessage": "This field is required",
                          "showInputLabel": true,
                          "labelContent": "text_232",
                          "labelClass": "form-label d-none",
                          "isLabelAfter": false,
                          "showAdditionalInputContent": false,
                          "additionalInputContent": "",
                          "isAdditonalInputAfter": true,
                          "draggable": false
                        },
                        {
                          "type": "text",
                          "name": "plasmid_info",
                          "id": "plasmid_info",
                          "value": "",
                          "placeholder": "",
                          "multiline": false,
                          "classname": "d-none",
                          "regularExpression": "",
                          "disabled": true,
                          "required": false,
                          "errorMessage": {
                            "noData": "This field is required",
                            "regexFail": ""
                          },
                          "validationButtonType": "",
                          "rows": 0,
                          "columns": 0,
                          "onDemandError": false,
                          "customValidation": [],
                          "multiError": false,
                          "applicationError": "",
                          "wrapperClassName": "",
                          "requiredErrorMessage": "This field is required",
                          "showInputLabel": true,
                          "labelContent": "text_232",
                          "labelClass": "form-label d-none",
                          "isLabelAfter": false,
                          "showAdditionalInputContent": false,
                          "additionalInputContent": "",
                          "isAdditonalInputAfter": true,
                          "draggable": false
                        }
                      ],
                      "name": "box_644",
                      "id": "box_716",
                      "wrapperType": "BOX",
                      "fields": [],
                      "type": "",
                      "classname": "",
                      "addButton": {
                        "classname": "",
                        "value": ""
                      },
                      "removeButton": {
                        "classname": "",
                        "value": ""
                      },
                      "wrapperClassName": ""
                    },
                    "classname": ""
                  },
                  {
                    "fields": {
                      "control": [
                        {
                          "type": "text",
                          "name": "bacterial_resistance",
                          "id": "bacterial_resistance",
                          "value": "",
                          "placeholder": "",
                          "multiline": false,
                          "classname": "form-control ",
                          "regularExpression": "",
                          "disabled": true,
                          "required": false,
                          "errorMessage": {
                            "noData": "This field is required",
                            "regexFail": ""
                          },
                          "validationButtonType": "",
                          "rows": 0,
                          "columns": 0,
                          "onDemandError": false,
                          "customValidation": [],
                          "multiError": false,
                          "applicationError": "",
                          "wrapperClassName": "",
                          "requiredErrorMessage": "This field is required",
                          "showInputLabel": true,
                          "labelContent": "text_232",
                          "labelClass": "form-label d-none",
                          "isLabelAfter": false,
                          "showAdditionalInputContent": false,
                          "additionalInputContent": "",
                          "isAdditonalInputAfter": true,
                          "draggable": false
                        }
                      ],
                      "name": "box_644",
                      "id": "box_716",
                      "wrapperType": "BOX",
                      "fields": [],
                      "type": "",
                      "classname": "",
                      "addButton": {
                        "classname": "",
                        "value": ""
                      },
                      "removeButton": {
                        "classname": "",
                        "value": ""
                      },
                      "wrapperClassName": ""
                    },
                    "classname": ""
                  },
                  {
                    "fields": {
                      "control": [
                        {
                          "type": "text",
                          "name": "mammalian_resistance",
                          "id": "mammalian_resistance",
                          "value": "",
                          "placeholder": "",
                          "multiline": false,
                          "classname": "form-control ",
                          "regularExpression": "",
                          "disabled": true,
                          "required": false,
                          "errorMessage": {
                            "noData": "This field is required",
                            "regexFail": ""
                          },
                          "validationButtonType": "",
                          "rows": 0,
                          "columns": 0,
                          "onDemandError": false,
                          "customValidation": [],
                          "multiError": false,
                          "applicationError": "",
                          "wrapperClassName": "",
                          "requiredErrorMessage": "This field is required",
                          "showInputLabel": true,
                          "labelContent": "text_232",
                          "labelClass": "form-label d-none",
                          "isLabelAfter": false,
                          "showAdditionalInputContent": false,
                          "additionalInputContent": "",
                          "isAdditonalInputAfter": true,
                          "draggable": false
                        }
                      ],
                      "name": "box_644",
                      "id": "box_716",
                      "wrapperType": "BOX",
                      "fields": [],
                      "type": "",
                      "classname": "",
                      "addButton": {
                        "classname": "",
                        "value": ""
                      },
                      "removeButton": {
                        "classname": "",
                        "value": ""
                      },
                      "wrapperClassName": ""
                    },
                    "classname": ""
                  }
                ]
              }
            }
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_308",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_122",
        "fields": [
          {
            "control": [
              {
                "type": "label",
                "name": "Cell Line Info",
                "classname": "",
                "for": "",
                "required": false,
                "wrapperClassName": "",
                "id": "label_95",
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_77",
            "classname": "col-12  add-heading heading-without-icon ",
            "wrapperType": "BOX",
            "id": "wrapper_676",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
               type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "cell_line",
                "id": "cell_line",
                "validationButtonType": "ABP-Submit",
                "classname": "",
                "required": true,
                "apiEndPoints": [
                  {
                    "type": "init",
                    "method": "post",
                    "url": "inventory_item",
                    "payload": {
                      "user_input": "",
                      "perma": "cell-lines"
                    }
                  }
                ],
                "options": [],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": true,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showInputLabel": true,
                "labelContent": "Expression Cell Line",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_529",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_271",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
               type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "media",
                "id": "media",
                "validationButtonType": "ABP-Submit",
                "classname": "",
                "required": true,
                "apiEndPoints": [
                  {
                    "type": "init",
                    "method": "post",
                    "url": "inventory_item",
                    "payload": {
                      "user_input": "",
                      "perma": "cell-culture-media"
                    }
                  }
                ],
                "options": [],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": true,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showInputLabel": true,
                "labelContent": "Media",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_819",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_164",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
               type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "transfection_reagent",
                "id": "transfection_reagent",
                "validationButtonType": "ABP-Submit",
                "classname": "",
                "required": true,
                "apiEndPoints": [
                  {
                    "type": "init",
                    "method": "post",
                    "url": "inventory_item",
                    "payload": {
                      "user_input": "",
                      "perma": "lab-reagents"
                    }
                  }
                ],
                "options": [],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": true,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showInputLabel": true,
                "labelContent": "Transfection Reagents",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_299",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_512",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "passage_no",
                "id": "passage_no",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "ABP-Submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [
                  {
                    "regularExpression": "/^d+(.d+)?$/",
                    "errorMessage": "Please type a number"
                  }
                ],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Passage No",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_80",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_170",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_787",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_181",
        "fields": [
          {
            "control": [
              {
                "type": "label",
                "name": "Cell Line Growth",
                "classname": "",
                "for": "",
                "required": false,
                "wrapperClassName": "",
                "id": "label_909",
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_29",
            "classname": "col-12  add-heading heading-without-icon ",
            "wrapperType": "BOX",
            "id": "wrapper_117",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
               type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "plate_type",
                "id": "plate_type",
                "validationButtonType": "ABP-Submit",
                "classname": "",
                "required": true,
                "apiEndPoints": [],
                "options": [
                  {
                    "id": 1,
                    "value": "Well Plate"
                  },
                  {
                    "id": 2,
                    "value": "Tube"
                  },
                  {
                    "id": 3,
                    "value": "Culture flask"
                  }
                ],
                "controlPoints": [
                  {
                    "type": "change",
                    "value": null,
                    "Mapto": [
                      {
                        "id": "main.wrapper_181.wrapper_126",
                        "type": "hideField"
                      },
                      {
                        "id": "main.wrapper_181.wrapper_337",
                        "type": "hideField"
                      }
                    ]
                  },
                  {
                    "type": "change",
                    "value": {
                      "id": 1,
                      "value": "Well Plate"
                    },
                    "Mapto": [
                      {
                        "id": "main.wrapper_181.wrapper_126",
                        "type": "showField"
                      },
                      {
                        "id": "main.wrapper_181.wrapper_337",
                        "type": "hideField"
                      },
                      {
                        "id": "main.wrapper_181.wrapper_3357",
                        "type": "hideField"
                      }
                    ]
                  },
                  {
                    "type": "change",
                    "value": {
                      "id": 2,
                      "value": "Tube"
                    },
                    "Mapto": [
                      {
                        "id": "main.wrapper_181.wrapper_126",
                        "type": "hideField"
                      },
                      {
                        "id": "main.wrapper_181.wrapper_337",
                        "type": "showField"
                      },
                      {
                        "id": "main.wrapper_181.wrapper_3357",
                        "type": "hideField"
                      }
                    ]
                  },
                  {
                    "type": "change",
                    "value": {
                      "id": 3,
                      "value": "Culture flask"
                    },
                    "Mapto": [
                      {
                        "id": "main.wrapper_181.wrapper_126",
                        "type": "hideField"
                      },
                      {
                        "id": "main.wrapper_181.wrapper_337",
                        "type": "hideField"
                      },
                      {
                        "id": "main.wrapper_181.wrapper_3357",
                        "type": "showField"
                      }
                    ]
                  }
                ],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": true,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showInputLabel": true,
                "labelContent": "Container Type",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_31",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_872",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "plate_id",
                "id": "plate_id",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "ABP-Submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Plate ID",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_403",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_126",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "tube_id",
                "id": "tube_id",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "ABP-Submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Tube ID",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_268",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_337",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "flask_id",
                "id": "flask_id",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "ABP-Submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Flask ID",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_2468",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_3357",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "incubation_time",
                "id": "incubation_time",
                "value": "",
                "placeholder": "",
                "validationButtonType": "ABP-Submit",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [
                  {
                    "regularExpression": "/^d+(.d+)?$/",
                    "errorMessage": "Please type a number"
                  }
                ],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Incubation Time(h)",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_832",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_461",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "temperature",
                "id": "temperature",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "ABP-Submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [
                  {
                    "regularExpression": "/^-?d+(.d+)?$/",
                    "errorMessage": "Please type a number"
                  }
                ],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Temp (°C)",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_644",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_508",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "supernatant_volume",
                "id": "supernatant_volume",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "ABP-Submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [
                  {
                    "regularExpression": "/^d+(.d+)?$/",
                    "errorMessage": "Please type a number"
                  }
                ],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Supernatant  Vol(mL)",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_741",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_455",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_133",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_329",
        "fields": [
          {
            "control": [
              {
                "type": "text",
                "name": "exprmntal_notes",
                "id": "exprmntal_notes",
                "value": "",
                "placeholder": "",
                "multiline": true,
                "classname": "form-control textarea",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "ABP-Submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Experimental Notes/Comments",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_588",
            "classname": "col-12",
            "wrapperType": "BOX",
            "id": "wrapper_345",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      }
    ],
    "type": "",
    "classname": ""
  },

  //Antibody Production & Purification WORKFLOW Purification by Protein
  {
    "jsonId": 77,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
      {
        "control": [],
        "type": "",
        "name": "wrapper_871",
        "classname": "row mb-4",
        "wrapperType": "BOX",
        "id": "wrapper_17",
        "fields": [
          {
            "control": [
              {
               type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "protocol",
                "id": "protocol",
                "validationButtonType": "AB-PU-submit",
                "classname": "",
                "required": true,
                "apiEndPoints": [
                  {
                    "type": "init",
                    "method": "get",
                    "url": "protocol/protocol-list"
                  },
                  {
                    "type": "change",
                    "method": "get",
                    "Mapto": [
                      {
                        "id": "",
                        "displayKey": "value",
                        "urlParameterValues": {
                          "id": null
                        }
                      }
                    ],
                    "url": "protocol/${id}"
                  }
                ],
                "options": [],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": true,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showInputLabel": true,
                "labelContent": "Protocol",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_392",
            "classname": "col-3",
            "wrapperType": "BOX",
            "id": "wrapper_650",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
                "type": "button",
                "id": "button_566",
                "value": "<div class=\"custom-tooltip\"><p>View</p></div>",
                "buttonType": "protocol_info",
                "classname": "view",
                "divClass": "",
                "controlEndPoints": [],
                "disabled": false,
                "validationEndPoints": [],
                "invalid": false,
                "name": "button_779",
                "wrapperClassName": "actions position-relative",
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_861",
            "classname": "col-1 mt-4 pt-2",
            "wrapperType": "BOX",
            "id": "wrapper_41",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_983",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_156",
        "fields": [
          {
            "control": [
              {
                "type": "label",
                "name": "Experimental Setup",
                "classname": "",
                "for": "",
                "required": false,
                "wrapperClassName": "",
                "id": "label_432",
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_95",
            "classname": "col-12  add-heading heading-without-icon ",
            "wrapperType": "BOX",
            "id": "wrapper_276",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_73",
        "classname": "",
        "wrapperType": "BOX",
        "id": "wrapper_364",
        "fields": [
          {
            "control": [
              {
                "type": "text",
                "name": "supernatant_vol",
                "id": "supernatant_vol",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": true,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Supernatant Obtained(ml)",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_392",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_650",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [],
            "type": "",
            "name": "purification_table",
            "classname": "table experimental-table",
            "wrapperType": "TABLE",
            "id": "purification_table",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false,
            "tableFields": {
              "showHead": true,
              "isVertical": false,
              "isIteratable": true,
              "addButton": {
                "classname": "add-expand",
                "name": "addRow",
                "value": "<div class=\"custom-tooltip\"><p>Add Row</div>"
              },
              "removeButton": {
                "classname": "add-minus",
                "name": "removeRow",
                "value": "<div class=\"custom-tooltip\"><p>Remove Row</div>"
              },
              "tableHead": {
                "heading": [
                  {
                    "value": "Supernatant used  for Purification<span class=\"text-initial\">(mL)</span>",
                    "classname": ""
                  },
                  {
                    "value": "Protein Affinity Bead Used",
                    "classname": ""
                  },
                  {
                    "value": "Reagent",
                    "classname": ""
                  },
                  {
                    "value": "Bead Vol<span class=\"text-initial\">(mL)</span>",
                    "classname": ""
                  },
                  {
                    "value": "Incubation Time<span class=\"text-lowercase\">(h)</span>",
                    "classname": ""
                  },
                  {
                    "value": "Temp (°C)",
                    "classname": ""
                  },
                  {
                    "value": "Action",
                    "classname": ""
                  },
                  {
                    "value": "",
                    "classname": ""
                  }
                ],
                "classname": ""
              },
              "initRecordCount": 1,
              "showRecordCount": false,
              "recordCounterConfig": {
                "classname": "",
                "adjValue": "",
                "isAdjValueAfter": false
              },
              "tableRow": {
                "name": "",
                "classname": "trclass",
                "tableCols": [
                  {
                    "fields": {
                      "control": [
                        {
                          "type": "text",
                          "name": "supernatant",
                          "id": "supernatant",
                          "value": "",
                          "placeholder": "",
                          "multiline": false,
                          "classname": "form-control ",
                          "regularExpression": "",
                          "disabled": false,
                          "required": true,
                          "errorMessage": {
                            "noData": "This field is required",
                            "regexFail": ""
                          },
                          "validationButtonType": "AB-PU-submit",
                          "rows": 0,
                          "columns": 0,
                          "onDemandError": false,
                          "customValidation": [
                            {
                              "regularExpression": "/^d+(.d+)?$/",
                              "errorMessage": "Please type a number"
                            }
                          ],
                          "multiError": false,
                          "applicationError": "",
                          "wrapperClassName": "",
                          "requiredErrorMessage": "This field is required",
                          "showInputLabel": true,
                          "labelContent": "text_855",
                          "labelClass": "form-label d-none",
                          "isLabelAfter": false,
                          "showAdditionalInputContent": false,
                          "additionalInputContent": "",
                          "isAdditonalInputAfter": true,
                          "draggable": false
                        }
                      ],
                      "name": "box_4430",
                      "id": "box_3567",
                      "wrapperType": "BOX",
                      "fields": [],
                      "type": "",
                      "classname": "",
                      "addButton": {
                        "classname": "",
                        "value": ""
                      },
                      "removeButton": {
                        "classname": "",
                        "value": ""
                      },
                      "wrapperClassName": ""
                    },
                    "classname": ""
                  },
                  {
                    "fields": {
                      "control": [
                        {
                         type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                          "name": "protein",
                          "id": "protein",
                          "validationButtonType": "AB-PU-submit",
                          "classname": "",
                          "required": true,
                          "apiEndPoints": [
                            {
                              "type": "init",
                              "method": "post",
                              "Mapto": [
                                {
                                  "id": "main.antibodyTable.${i}.box_516.protein",
                                  "displayKey": "change",
                                  "urlParameterValues": {
                                    "id": null,
                                    "patchProperty": "value"
                                  }
                                }
                              ],
                              "url": "masterdata",
                              "payload": {
                                "data_types": [
                                  "protein"
                                ]
                              },
                              "inv_type_perma": "string"
                            },
                            {
                              "type": "change",
                              "method": "patchVal",
                              "Mapto": [
                                {
                                  "id": "main.antibodyTable.${i}.box_516.protein",
                                  "displayKey": "change",
                                  "urlParameterValues": {
                                    "id": null,
                                    "patchProperty": "protein",
                                    "value": null
                                  },
                                  "payload": {
                                    "data_types": [
                                      "protein"
                                    ]
                                  },
                                  "inv_type_perma": "string"
                                }
                              ],
                              "url": "masterdata"
                            }
                          ],
                          "options": [],
                          "multiple": false,
                          "isClearable": false,
                          "isStatus": false,
                          "search": true,
                          "singleSelect": true,
                          "showLabel": "value",
                          "errorMessage": {
                            "noData": "This field is required"
                          },
                          "value": "",
                          "imageUrl": "",
                          "disabled": false,
                          "wrapperClassName": "",
                          "connectedArrays": [],
                          "showInputLabel": true,
                          "labelContent": "Plasmid1",
                          "labelClass": "form-label d-none",
                          "isLabelAfter": false,
                          "showAdditionalInputContent": false,
                          "additionalInputContent": "",
                          "isAdditonalInputAfter": true,
                          "draggable": false
                        }
                      ],
                      "name": "box_999",
                      "id": "box_936",
                      "wrapperType": "BOX",
                      "fields": [],
                      "type": "",
                      "classname": "",
                      "addButton": {
                        "classname": "",
                        "value": ""
                      },
                      "removeButton": {
                        "classname": "",
                        "value": ""
                      },
                      "wrapperClassName": ""
                    },
                    "classname": ""
                  },
                  {
                    "fields": {
                      "control": [
                        {
                         type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                          "name": "lab_reagent",
                          "id": "lab_reagent",
                          "validationButtonType": "AB-PU-submit",
                          "classname": "",
                          "required": true,
                          "apiEndPoints": [
                            {
                              "type": "init",
                              "method": "post",
                              "url": "inventory_item",
                              "payload": {
                                "user_input": "",
                                "sub_type_id": 0,
                                "perma": "lab-reagents"
                              }
                            }
                          ],
                          "options": [],
                          "multiple": false,
                          "isClearable": false,
                          "isStatus": false,
                          "search": true,
                          "singleSelect": true,
                          "showLabel": "value",
                          "errorMessage": {
                            "noData": "This field is required"
                          },
                          "value": "",
                          "imageUrl": "",
                          "disabled": false,
                          "wrapperClassName": "",
                          "connectedArrays": [],
                          "showInputLabel": true,
                          "labelContent": "dropdown_431",
                          "labelClass": "form-label d-none",
                          "isLabelAfter": false,
                          "showAdditionalInputContent": false,
                          "additionalInputContent": "",
                          "isAdditonalInputAfter": true,
                          "draggable": false
                        }
                      ],
                      "name": "box_644",
                      "id": "box_716",
                      "wrapperType": "BOX",
                      "fields": [],
                      "type": "",
                      "classname": "",
                      "addButton": {
                        "classname": "",
                        "value": ""
                      },
                      "removeButton": {
                        "classname": "",
                        "value": ""
                      },
                      "wrapperClassName": ""
                    },
                    "classname": ""
                  },
                  {
                    "fields": {
                      "control": [
                        {
                          "type": "text",
                          "name": "bead_vol",
                          "id": "bead_vol",
                          "value": "",
                          "placeholder": "",
                          "multiline": false,
                          "classname": "form-control ",
                          "regularExpression": "",
                          "disabled": false,
                          "required": true,
                          "errorMessage": {
                            "noData": "This field is required",
                            "regexFail": ""
                          },
                          "validationButtonType": "AB-PU-submit",
                          "rows": 0,
                          "columns": 0,
                          "onDemandError": true,
                          "customValidation": [
                            {
                              "regularExpression": "/^d+(.d+)?$/",
                              "errorMessage": "Please type a number"
                            }
                          ],
                          "multiError": false,
                          "applicationError": "",
                          "wrapperClassName": "",
                          "requiredErrorMessage": "This field is required",
                          "showInputLabel": true,
                          "labelContent": "text_651",
                          "labelClass": "form-label d-none",
                          "isLabelAfter": false,
                          "showAdditionalInputContent": false,
                          "additionalInputContent": "",
                          "isAdditonalInputAfter": true,
                          "draggable": false
                        }
                      ],
                      "name": "box_69",
                      "id": "box_71",
                      "wrapperType": "BOX",
                      "fields": [],
                      "type": "",
                      "classname": "",
                      "addButton": {
                        "classname": "",
                        "value": ""
                      },
                      "removeButton": {
                        "classname": "",
                        "value": ""
                      },
                      "wrapperClassName": ""
                    },
                    "classname": ""
                  },
                  {
                    "fields": {
                      "control": [
                        {
                          "type": "text",
                          "name": "inc_time",
                          "id": "inc_time",
                          "value": "",
                          "placeholder": "",
                          "multiline": false,
                          "classname": "form-control ",
                          "regularExpression": "",
                          "disabled": false,
                          "required": true,
                          "errorMessage": {
                            "noData": "This field is required",
                            "regexFail": ""
                          },
                          "validationButtonType": "AB-PU-submit",
                          "rows": 0,
                          "columns": 0,
                          "onDemandError": false,
                          "customValidation": [
                            {
                              "regularExpression": "/^d+(.d+)?$/",
                              "errorMessage": "Please type a number"
                            }
                          ],
                          "multiError": false,
                          "applicationError": "",
                          "wrapperClassName": "",
                          "requiredErrorMessage": "This field is required",
                          "showInputLabel": true,
                          "labelContent": "text_855",
                          "labelClass": "form-label d-none",
                          "isLabelAfter": false,
                          "showAdditionalInputContent": false,
                          "additionalInputContent": "",
                          "isAdditonalInputAfter": true,
                          "draggable": false
                        }
                      ],
                      "name": "box_430",
                      "id": "box_357",
                      "wrapperType": "BOX",
                      "fields": [],
                      "type": "",
                      "classname": "",
                      "addButton": {
                        "classname": "",
                        "value": ""
                      },
                      "removeButton": {
                        "classname": "",
                        "value": ""
                      },
                      "wrapperClassName": ""
                    },
                    "classname": ""
                  },
                  {
                    "fields": {
                      "control": [
                        {
                          "type": "text",
                          "name": "temp",
                          "id": "temp",
                          "value": "",
                          "placeholder": "",
                          "multiline": false,
                          "classname": "form-control ",
                          "regularExpression": "",
                          "disabled": false,
                          "required": true,
                          "errorMessage": {
                            "noData": "This field is required",
                            "regexFail": ""
                          },
                          "validationButtonType": "AB-PU-submit",
                          "rows": 0,
                          "columns": 0,
                          "onDemandError": false,
                          "customValidation": [
                            {
                              "regularExpression": "/^-?d+(.d+)?$/",
                              "errorMessage": "Please type a number"
                            }
                          ],
                          "multiError": false,
                          "applicationError": "",
                          "wrapperClassName": "",
                          "requiredErrorMessage": "This field is required",
                          "showInputLabel": true,
                          "labelContent": "text_986",
                          "labelClass": "form-label d-none",
                          "isLabelAfter": false,
                          "showAdditionalInputContent": false,
                          "additionalInputContent": "",
                          "isAdditonalInputAfter": true,
                          "draggable": false
                        }
                      ],
                      "name": "box_986",
                      "id": "box_74",
                      "wrapperType": "BOX",
                      "fields": [],
                      "type": "",
                      "classname": "",
                      "addButton": {
                        "classname": "",
                        "value": ""
                      },
                      "removeButton": {
                        "classname": "",
                        "value": ""
                      },
                      "wrapperClassName": ""
                    },
                    "classname": ""
                  },
                  {
                    "fields": {
                      "control": [],
                      "name": "box_858",
                      "id": "box_449",
                      "wrapperType": "BOX",
                      "fields": [],
                      "type": "",
                      "classname": "",
                      "addButton": {
                        "classname": "",
                        "value": ""
                      },
                      "removeButton": {
                        "classname": "",
                        "value": ""
                      },
                      "wrapperClassName": ""
                    },
                    "classname": ""
                  }
                ]
              }
            }
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_308",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_122",
        "fields": [
          {
            "control": [
              {
                "type": "label",
                "name": "Results & Conclusion",
                "classname": "",
                "for": "",
                "required": false,
                "wrapperClassName": "",
                "id": "label_95",
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_77",
            "classname": "col-12  add-heading heading-without-icon ",
            "wrapperType": "BOX",
            "id": "wrapper_676",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "type": "",
        "name": "antibodyTable",
        "classname": "table result-table",
        "wrapperType": "TABLE",
        "id": "antibodyTable",
        "fields": [],
        "head": "",
        "tabHeadClass": "",
        "draggable": false,
        "tableFields": {
          "showHead": true,
          "isVertical": false,
          "isIteratable": false,
          "addButton": {
            "classname": "btn btn-primary",
            "name": "",
            "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
          },
          "removeButton": {
            "classname": "btn btn-danger",
            "name": "",
            "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
          },
          "tableHead": {
            "heading": [
              {
                "value": "Antibody  Name",
                "classname": ""
              },
              {
                "value": "Protein Affinity Bead Used",
                "classname": ""
              },
              {
                "value": "QC Status",
                "classname": ""
              },
              {
                "value": "Elution  Vol<span class=\"text-initial\">(mL)</span>",
                "classname": ""
              },
              {
                "value": "A280",
                "classname": ""
              },
              {
                "value": "Conc<span class=\"text-lowercase\">(µg/mL)</span> ",
                "classname": ""
              },
              {
                "value": "Yield<span class=\"text-lowercase\">(µg)</span>",
                "classname": ""
              }
            ],
            "classname": ""
          },
          "initRecordCount": 1,
          "showRecordCount": false,
          "recordCounterConfig": {
            "classname": "",
            "adjValue": "",
            "isAdjValueAfter": false
          },
          "tableRow": {
            "name": "",
            "classname": "trclass",
            "tableCols": [
              {
                "fields": {
                  "control": [
                    {
                      "type": "text",
                      "name": "antibody",
                      "id": "antibody",
                      "value": "",
                      "placeholder": "",
                      "multiline": false,
                      "classname": "form-control ",
                      "regularExpression": "",
                      "disabled": true,
                      "required": true,
                      "errorMessage": {
                        "noData": "This field is required",
                        "regexFail": ""
                      },
                      "validationButtonType": "AB-PU-submit",
                      "rows": 0,
                      "columns": 0,
                      "onDemandError": false,
                      "customValidation": [],
                      "multiError": false,
                      "applicationError": "",
                      "wrapperClassName": "",
                      "requiredErrorMessage": "This field is required",
                      "showInputLabel": true,
                      "labelContent": "text_523",
                      "labelClass": "form-label d-none",
                      "isLabelAfter": false,
                      "showAdditionalInputContent": false,
                      "additionalInputContent": "",
                      "isAdditonalInputAfter": true,
                      "draggable": false
                    }
                  ],
                  "head": "",
                  "name": "box_47",
                  "id": "box_38",
                  "wrapperType": "BOX",
                  "fields": [],
                  "type": "",
                  "classname": ""
                },
                "classname": ""
              },
              {
                "fields": {
                  "control": [
                    {
                      "type": "text",
                      "name": "protein",
                      "id": "protein",
                      "value": "",
                      "placeholder": "",
                      "multiline": false,
                      "classname": "form-control ",
                      "regularExpression": "",
                      "disabled": true,
                      "required": true,
                      "errorMessage": {
                        "noData": "This field is required",
                        "regexFail": ""
                      },
                      "validationButtonType": "AB-PU-submit",
                      "rows": 0,
                      "columns": 0,
                      "onDemandError": false,
                      "customValidation": [],
                      "multiError": false,
                      "applicationError": "",
                      "wrapperClassName": "",
                      "requiredErrorMessage": "This field is required",
                      "showInputLabel": true,
                      "labelContent": "text_234",
                      "labelClass": "form-label d-none",
                      "isLabelAfter": false,
                      "showAdditionalInputContent": false,
                      "additionalInputContent": "",
                      "isAdditonalInputAfter": true,
                      "draggable": false
                    }
                  ],
                  "name": "box_989",
                  "id": "box_516",
                  "wrapperType": "BOX",
                  "fields": [],
                  "type": "",
                  "classname": "",
                  "addButton": {
                    "classname": "",
                    "value": ""
                  },
                  "removeButton": {
                    "classname": "",
                    "value": ""
                  },
                  "wrapperClassName": ""
                },
                "classname": ""
              },
              {
                "fields": {
                  "control": [
                    {
                     type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                      "name": "qc_status",
                      "id": "qc_status",
                      "validationButtonType": "AB-PU-submit",
                      "classname": "",
                      "required": true,
                      "apiEndPoints": [],
                      "options": [
                        {
                          "id": 1,
                          "value": "Passed"
                        },
                        {
                          "id": 2,
                          "value": "Failed"
                        }
                      ],
                      "multiple": false,
                      "isClearable": false,
                      "isStatus": false,
                      "search": true,
                      "singleSelect": true,
                      "showLabel": "value",
                      "errorMessage": {
                        "noData": "This field is required"
                      },
                      "value": "",
                      "imageUrl": "",
                      "disabled": false,
                      "wrapperClassName": "",
                      "connectedArrays": [],
                      "showInputLabel": true,
                      "labelContent": "dropdown_65",
                      "labelClass": "form-label d-none",
                      "isLabelAfter": false,
                      "showAdditionalInputContent": false,
                      "additionalInputContent": "",
                      "isAdditonalInputAfter": true,
                      "draggable": false
                    }
                  ],
                  "name": "box_851",
                  "id": "box_258",
                  "wrapperType": "BOX",
                  "fields": [],
                  "type": "",
                  "classname": "",
                  "addButton": {
                    "classname": "",
                    "value": ""
                  },
                  "removeButton": {
                    "classname": "",
                    "value": ""
                  },
                  "wrapperClassName": ""
                },
                "classname": ""
              },
              {
                "fields": {
                  "control": [
                    {
                      "type": "text",
                      "name": "elution_volu",
                      "id": "elution_volu",
                      "value": "",
                      "placeholder": "",
                      "multiline": false,
                      "classname": "form-control ",
                      "regularExpression": "",
                      "disabled": false,
                      "required": true,
                      "errorMessage": {
                        "noData": "This field is required",
                        "regexFail": ""
                      },
                      "validationButtonType": "AB-PU-submit",
                      "rows": 0,
                      "columns": 0,
                      "onDemandError": true,
                      "customValidation": [
                        {
                          "regularExpression": "/^d+(.d+)?$/",
                          "errorMessage": "Please type a number"
                        }
                      ],
                      "multiError": false,
                      "applicationError": "",
                      "wrapperClassName": "",
                      "requiredErrorMessage": "This field is required",
                      "showInputLabel": true,
                      "labelContent": "text_234",
                      "labelClass": "form-label d-none",
                      "isLabelAfter": false,
                      "showAdditionalInputContent": false,
                      "additionalInputContent": "",
                      "isAdditonalInputAfter": true,
                      "draggable": false
                    }
                  ],
                  "name": "box_988",
                  "id": "box_945",
                  "wrapperType": "BOX",
                  "fields": [],
                  "type": "",
                  "classname": "",
                  "addButton": {
                    "classname": "",
                    "value": ""
                  },
                  "removeButton": {
                    "classname": "",
                    "value": ""
                  },
                  "wrapperClassName": ""
                },
                "classname": ""
              },
              {
                "fields": {
                  "control": [
                    {
                      "type": "text",
                      "name": "a280",
                      "id": "a280",
                      "value": "",
                      "placeholder": "",
                      "multiline": false,
                      "classname": "form-control ",
                      "regularExpression": "",
                      "disabled": false,
                      "required": true,
                      "errorMessage": {
                        "noData": "This field is required",
                        "regexFail": ""
                      },
                      "validationButtonType": "AB-PU-submit",
                      "rows": 0,
                      "columns": 0,
                      "onDemandError": true,
                      "customValidation": [
                        {
                          "regularExpression": "/^d+(.d+)?$/",
                          "errorMessage": "Please type a number"
                        }
                      ],
                      "multiError": false,
                      "applicationError": "",
                      "wrapperClassName": "",
                      "requiredErrorMessage": "This field is required",
                      "showInputLabel": true,
                      "labelContent": "text_287",
                      "labelClass": "form-label d-none",
                      "isLabelAfter": false,
                      "showAdditionalInputContent": false,
                      "additionalInputContent": "",
                      "isAdditonalInputAfter": true,
                      "draggable": false
                    }
                  ],
                  "name": "box_767",
                  "id": "box_643",
                  "wrapperType": "BOX",
                  "fields": [],
                  "type": "",
                  "classname": "",
                  "addButton": {
                    "classname": "",
                    "value": ""
                  },
                  "removeButton": {
                    "classname": "",
                    "value": ""
                  },
                  "wrapperClassName": ""
                },
                "classname": ""
              },
              {
                "fields": {
                  "control": [
                    {
                      "type": "text",
                      "name": "conc",
                      "id": "conc",
                      "value": "",
                      "placeholder": "",
                      "multiline": false,
                      "classname": "form-control ",
                      "regularExpression": "",
                      "disabled": false,
                      "required": true,
                      "errorMessage": {
                        "noData": "This field is required",
                        "regexFail": ""
                      },
                      "validationButtonType": "AB-PU-submit",
                      "rows": 0,
                      "columns": 0,
                      "onDemandError": true,
                      "customValidation": [
                        {
                          "regularExpression": "/^d+(.d+)?$/",
                          "errorMessage": "Please type a number"
                        }
                      ],
                      "multiError": false,
                      "applicationError": "",
                      "wrapperClassName": "",
                      "requiredErrorMessage": "This field is required",
                      "showInputLabel": true,
                      "labelContent": "text_929",
                      "labelClass": "form-label d-none",
                      "isLabelAfter": false,
                      "showAdditionalInputContent": false,
                      "additionalInputContent": "",
                      "isAdditonalInputAfter": true,
                      "draggable": false
                    }
                  ],
                  "name": "box_727",
                  "id": "box_878",
                  "wrapperType": "BOX",
                  "fields": [],
                  "type": "",
                  "classname": "",
                  "addButton": {
                    "classname": "",
                    "value": ""
                  },
                  "removeButton": {
                    "classname": "",
                    "value": ""
                  },
                  "wrapperClassName": ""
                },
                "classname": ""
              },
              {
                "fields": {
                  "control": [
                    {
                      "type": "text",
                      "name": "yield",
                      "id": "yield",
                      "value": "",
                      "placeholder": "",
                      "multiline": false,
                      "classname": "form-control ",
                      "regularExpression": "",
                      "disabled": true,
                      "required": true,
                      "errorMessage": {
                        "noData": "This field is required",
                        "regexFail": ""
                      },
                      "validationButtonType": "AB-PU-submit",
                      "rows": 0,
                      "columns": 0,
                      "onDemandError": false,
                      "customValidation": [
                        {
                          "regularExpression": "/^d+(.d+)?$/",
                          "errorMessage": "Please type a number"
                        }
                      ],
                      "multiError": false,
                      "applicationError": "",
                      "wrapperClassName": "",
                      "requiredErrorMessage": "This field is required",
                      "showInputLabel": true,
                      "labelContent": "text_488",
                      "labelClass": "form-label d-none",
                      "isLabelAfter": false,
                      "showAdditionalInputContent": false,
                      "additionalInputContent": "",
                      "isAdditonalInputAfter": true,
                      "draggable": false
                    }
                  ],
                  "name": "box_491",
                  "id": "box_885",
                  "wrapperType": "BOX",
                  "fields": [],
                  "type": "",
                  "classname": "",
                  "addButton": {
                    "classname": "",
                    "value": ""
                  },
                  "removeButton": {
                    "classname": "",
                    "value": ""
                  },
                  "wrapperClassName": "project-table"
                },
                "classname": ""
              }
            ]
          }
        }
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_133",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_329",
        "fields": [
          {
            "control": [
              {
                "type": "text",
                "name": "comments",
                "id": "comments",
                "value": "",
                "placeholder": "",
                "multiline": true,
                "classname": "form-control textarea",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Experimental Notes/Comments",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_588",
            "classname": "col-12",
            "wrapperType": "BOX",
            "id": "wrapper_345",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      }
    ],
    "type": "",
    "classname": ""
  },

  //Antibody Production & Purification WORKFLOW Purification by FPLC
  {
    "jsonId": 79,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
      {
        "control": [],
        "type": "",
        "name": "wrapper_871",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_17",
        "fields": [
          {
            "control": [
              {
               type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "protocol",
                "id": "protocol",
                "validationButtonType": "FPLC-Submit",
                "classname": "",
                "required": true,
                "apiEndPoints": [
                  {
                    "type": "init",
                    "method": "get",
                    "url": "protocol/protocol-list"
                  },
                  {
                    "type": "change",
                    "method": "get",
                    "Mapto": [
                      {
                        "id": "",
                        "displayKey": "value",
                        "urlParameterValues": {
                          "id": null
                        }
                      }
                    ],
                    "url": "protocol/${id}"
                  }
                ],
                "options": [],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": true,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showInputLabel": true,
                "labelContent": "Protocol",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_392",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_650",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
                "type": "button",
                "id": "protocol_info",
                "value": "<div class=\"custom-tooltip\"><p>View</p></div>",
                "buttonType": "protocol_info",
                "classname": "view",
                "divClass": "",
                "controlEndPoints": [],
                "disabled": false,
                "validationEndPoints": [],
                "invalid": false,
                "name": "button_779",
                "wrapperClassName": "actions position-relative",
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_861",
            "classname": "col-1 mt-4 pt-2",
            "wrapperType": "BOX",
            "id": "wrapper_41",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_983",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_156",
        "fields": [
          {
            "control": [
              {
                "type": "label",
                "name": "Antibody",
                "classname": "",
                "for": "",
                "required": false,
                "wrapperClassName": "",
                "id": "label_624",
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_95",
            "classname": "col-12  add-heading heading-without-icon ",
            "wrapperType": "BOX",
            "id": "wrapper_276",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
                "type": "text",
                "name": "antibody_name",
                "id": "antibody_name",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": true,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "FPLC-Submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Antibody Name",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_602",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_908",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "supernatant_obtained",
                "id": "supernatant_obtained",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": true,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [
                  {
                    "regularExpression": "/^d+(.d+)?$/",
                    "errorMessage": "Please type a number"
                  }
                ],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Supernatant Obtained (mL)",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "validationButtonType": "FPLC-Submit",
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_740",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_63",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "fplc_suprntnt_usd",
                "id": "fplc_suprntnt_usd",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [
                  {
                    "regularExpression": "/^d+(.d+)?$/",
                    "errorMessage": "Please type a number"
                  }
                ],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Supernatant Used (mL)",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "validationButtonType": "FPLC-Submit",
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_740",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_63",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_903",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_259",
        "fields": [
          {
            "control": [
              {
                "type": "label",
                "name": "Concentrating & Buffer Exchange",
                "classname": "",
                "for": "",
                "required": false,
                "wrapperClassName": "",
                "id": "label_727",
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_359",
            "classname": "col-12  add-heading heading-without-icon ",
            "wrapperType": "BOX",
            "id": "wrapper_915",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
                "type": "text",
                "name": "fplc_conc_column",
                "id": "fplc_conc_column",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "FPLC-Submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Column",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_11",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_653",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
                "type": "text",
                "name": "final_volume",
                "id": "final_volume",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "FPLC-Submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [
                  {
                    "regularExpression": "/^d+(.d+)?$/",
                    "errorMessage": "Please type a number"
                  }
                ],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Final Vol(mL)",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_415",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_163",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "fplc_concentration",
                "id": "fplc_concentration",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "FPLC-Submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [
                  {
                    "regularExpression": "/^d+(.d+)?$/",
                    "errorMessage": "Please type a number"
                  }
                ],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Conc(µg/µL) ",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_478",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_165",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "fplc_yield_vol",
                "id": "fplc_yield_vol",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "FPLC-Submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [
                  {
                    "regularExpression": "/^d+(.d+)?$/",
                    "errorMessage": "Please type a number"
                  }
                ],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Yield (ug/ul)",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_181",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_485",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_120",
        "classname": "row mb-3",
        "wrapperType": "BOX",
        "id": "wrapper_668",
        "fields": [
          {
            "control": [
              {
                "type": "label",
                "name": "Chromatography Setup",
                "classname": "",
                "for": "",
                "required": false,
                "wrapperClassName": "",
                "id": "label_210",
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_752",
            "classname": "col-12  add-heading heading-without-icon ",
            "wrapperType": "BOX",
            "id": "wrapper_835",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
                "type": "text",
                "name": "fplc_chrm_column",
                "id": "fplc_chrm_column",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "FPLC-Submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Column",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_926",
            "classname": "col-3",
            "wrapperType": "BOX",
            "id": "wrapper_843",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
                "type": "text",
                "name": "flow_rate",
                "id": "flow_rate",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "FPLC-Submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [
                  {
                    "regularExpression": "/^d+(.d+)?$/",
                    "errorMessage": "Please type a number"
                  }
                ],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Flow  Rate(mL/min)",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_698",
            "classname": "col-3",
            "wrapperType": "BOX",
            "id": "wrapper_552",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
                "type": "text",
                "name": "vol_of_ab",
                "id": "vol_of_ab",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "FPLC-Submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [
                  {
                    "regularExpression": "/^d+(.d+)?$/",
                    "errorMessage": "Please type a number"
                  }
                ],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Vol of Ab  loaded(ul)",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_311",
            "classname": "col-3 ",
            "wrapperType": "BOX",
            "id": "wrapper_105",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
                "type": "button",
                "id": "button_254",
                "value": "<p>Add Solvent and Buffer details",
                "buttonType": "add_sb_details",
                "classname": "btn btn-primary",
                "divClass": "",
                "controlEndPoints": [],
                "disabled": false,
                "validationEndPoints": [],
                "invalid": false,
                "name": "button_440",
                "wrapperClassName": "",
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_276",
            "classname": "col-3 add-buffer",
            "wrapperType": "BOX",
            "id": "wrapper_425",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_308",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_122",
        "fields": [
          {
            "control": [
              {
                "type": "label",
                "name": "Results & Conclusion",
                "classname": "",
                "for": "",
                "required": false,
                "wrapperClassName": "",
                "id": "label_95",
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_77",
            "classname": "col-12  add-heading heading-without-icon ",
            "wrapperType": "BOX",
            "id": "wrapper_676",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "type": "",
        "name": "antibody_table",
        "classname": "table",
        "wrapperType": "TABLE",
        "id": "antibody_table",
        "fields": [],
        "head": "",
        "tabHeadClass": "",
        "draggable": false,
        "tableFields": {
          "showHead": true,
          "isVertical": false,
          "isIteratable": false,
          "addButton": {
            "classname": "btn btn-primary",
            "name": "",
            "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<circle cx=\"12\" cy=\"12\" r=\"9\" stroke=\"#222222\"/>\n<path d=\"M12 15L12 9\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n<path d=\"M15 12L9 12\" stroke=\"#222222\" stroke-linecap=\"square\"/>\n</svg>"
          },
          "removeButton": {
            "classname": "btn btn-danger",
            "name": "",
            "value": "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n<svg width=\"20px\" height=\"20px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4 7H20\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z\" stroke=\"#000000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>"
          },
          "tableHead": {
            "heading": [
              {
                "value": "Antibody  Name",
                "classname": ""
              },
              {
                "value": "QC Status",
                "classname": ""
              },
              {
                "value": "Elution  Vol<span class=\"text-initial\">(mL)</span>",
                "classname": ""
              },
              {
                "value": "A280",
                "classname": ""
              },
              {
                "value": "Conc<span class=\"text-initial\">(µg/mL)</span> ",
                "classname": ""
              },
              {
                "value": "Yield<span class=\"text-lowercase\">(µg)</span>",
                "classname": ""
              }
            ],
            "classname": ""
          },
          "initRecordCount": 1,
          "showRecordCount": false,
          "recordCounterConfig": {
            "classname": "",
            "adjValue": "",
            "isAdjValueAfter": false
          },
          "tableRow": {
            "name": "",
            "classname": "trclass",
            "tableCols": [
              {
                "fields": {
                  "control": [
                    {
                      "type": "text",
                      "name": "antibody_name1",
                      "id": "antibody_name1",
                      "value": "",
                      "placeholder": "",
                      "multiline": false,
                      "classname": "form-control ",
                      "regularExpression": "",
                      "disabled": true,
                      "required": true,
                      "errorMessage": {
                        "noData": "This field is required",
                        "regexFail": ""
                      },
                      "validationButtonType": "FPLC-Submit",
                      "rows": 0,
                      "columns": 0,
                      "onDemandError": false,
                      "customValidation": [],
                      "multiError": false,
                      "applicationError": "",
                      "wrapperClassName": "",
                      "requiredErrorMessage": "This field is required",
                      "showInputLabel": true,
                      "labelContent": "text_523",
                      "labelClass": "form-label d-none",
                      "isLabelAfter": false,
                      "showAdditionalInputContent": false,
                      "additionalInputContent": "",
                      "isAdditonalInputAfter": true,
                      "draggable": false
                    }
                  ],
                  "head": "",
                  "name": "box_47",
                  "id": "box_38",
                  "wrapperType": "BOX",
                  "fields": [],
                  "type": "",
                  "classname": ""
                },
                "classname": ""
              },
              {
                "fields": {
                  "control": [
                    {
                     type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                      "name": "fplc_qc_status",
                      "id": "fplc_qc_status",
                      "validationButtonType": "FPLC-Submit",
                      "classname": "",
                      "required": true,
                      "apiEndPoints": [],
                      "options": [
                        {
                          "id": 1,
                          "value": "Passed"
                        },
                        {
                          "id": 2,
                          "value": "Failed"
                        }
                      ],
                      "multiple": false,
                      "isClearable": false,
                      "isStatus": false,
                      "search": true,
                      "singleSelect": true,
                      "showLabel": "value",
                      "errorMessage": {
                        "noData": "This field is required"
                      },
                      "value": "",
                      "imageUrl": "",
                      "disabled": false,
                      "wrapperClassName": "",
                      "connectedArrays": [],
                      "showInputLabel": true,
                      "labelContent": "dropdown_65",
                      "labelClass": "form-label d-none",
                      "isLabelAfter": false,
                      "showAdditionalInputContent": false,
                      "additionalInputContent": "",
                      "isAdditonalInputAfter": true,
                      "draggable": false
                    }
                  ],
                  "name": "box_851",
                  "id": "box_258",
                  "wrapperType": "BOX",
                  "fields": [],
                  "type": "",
                  "classname": "",
                  "addButton": {
                    "classname": "",
                    "value": ""
                  },
                  "removeButton": {
                    "classname": "",
                    "value": ""
                  },
                  "wrapperClassName": ""
                },
                "classname": ""
              },
              {
                "fields": {
                  "control": [
                    {
                      "type": "text",
                      "name": "fplc_el_vol_aftr_qc",
                      "id": "fplc_el_vol_aftr_qc",
                      "value": "",
                      "placeholder": "",
                      "multiline": false,
                      "classname": "form-control ",
                      "regularExpression": "",
                      "disabled": false,
                      "required": true,
                      "errorMessage": {
                        "noData": "This field is required",
                        "regexFail": ""
                      },
                      "validationButtonType": "FPLC-Submit",
                      "rows": 0,
                      "columns": 0,
                      "onDemandError": true,
                      "customValidation": [
                        {
                          "regularExpression": "/^d+(.d+)?$/",
                          "errorMessage": "Please type a number"
                        }
                      ],
                      "multiError": false,
                      "applicationError": "",
                      "wrapperClassName": "",
                      "requiredErrorMessage": "This field is required",
                      "showInputLabel": true,
                      "labelContent": "text_234",
                      "labelClass": "form-label d-none",
                      "isLabelAfter": false,
                      "showAdditionalInputContent": false,
                      "additionalInputContent": "",
                      "isAdditonalInputAfter": true,
                      "draggable": false
                    }
                  ],
                  "name": "box_988",
                  "id": "box_945",
                  "wrapperType": "BOX",
                  "fields": [],
                  "type": "",
                  "classname": "",
                  "addButton": {
                    "classname": "",
                    "value": ""
                  },
                  "removeButton": {
                    "classname": "",
                    "value": ""
                  },
                  "wrapperClassName": ""
                },
                "classname": ""
              },
              {
                "fields": {
                  "control": [
                    {
                      "type": "text",
                      "name": "fplc_a_280",
                      "id": "fplc_a_280",
                      "value": "",
                      "placeholder": "",
                      "multiline": false,
                      "classname": "form-control ",
                      "regularExpression": "",
                      "disabled": false,
                      "required": true,
                      "errorMessage": {
                        "noData": "This field is required",
                        "regexFail": ""
                      },
                      "validationButtonType": "FPLC-Submit",
                      "rows": 0,
                      "columns": 0,
                      "onDemandError": false,
                      "customValidation": [
                        {
                          "regularExpression": "/^d+(.d+)?$/",
                          "errorMessage": "Please type a number"
                        }
                      ],
                      "multiError": false,
                      "applicationError": "",
                      "wrapperClassName": "",
                      "requiredErrorMessage": "This field is required",
                      "showInputLabel": true,
                      "labelContent": "text_287",
                      "labelClass": "form-label d-none",
                      "isLabelAfter": false,
                      "showAdditionalInputContent": false,
                      "additionalInputContent": "",
                      "isAdditonalInputAfter": true,
                      "draggable": false
                    }
                  ],
                  "name": "box_767",
                  "id": "box_643",
                  "wrapperType": "BOX",
                  "fields": [],
                  "type": "",
                  "classname": "",
                  "addButton": {
                    "classname": "",
                    "value": ""
                  },
                  "removeButton": {
                    "classname": "",
                    "value": ""
                  },
                  "wrapperClassName": ""
                },
                "classname": ""
              },
              {
                "fields": {
                  "control": [
                    {
                      "type": "text",
                      "name": "fplc_conc_aftr_qc",
                      "id": "fplc_conc_aftr_qc",
                      "value": "",
                      "placeholder": "",
                      "multiline": false,
                      "classname": "form-control ",
                      "regularExpression": "",
                      "disabled": false,
                      "required": true,
                      "errorMessage": {
                        "noData": "This field is required",
                        "regexFail": ""
                      },
                      "validationButtonType": "FPLC-Submit",
                      "rows": 0,
                      "columns": 0,
                      "onDemandError": true,
                      "customValidation": [
                        {
                          "regularExpression": "/^d+(.d+)?$/",
                          "errorMessage": "Please type a number"
                        }
                      ],
                      "multiError": false,
                      "applicationError": "",
                      "wrapperClassName": "",
                      "requiredErrorMessage": "This field is required",
                      "showInputLabel": true,
                      "labelContent": "text_929",
                      "labelClass": "form-label d-none",
                      "isLabelAfter": false,
                      "showAdditionalInputContent": false,
                      "additionalInputContent": "",
                      "isAdditonalInputAfter": true,
                      "draggable": false
                    }
                  ],
                  "name": "box_727",
                  "id": "box_878",
                  "wrapperType": "BOX",
                  "fields": [],
                  "type": "",
                  "classname": "",
                  "addButton": {
                    "classname": "",
                    "value": ""
                  },
                  "removeButton": {
                    "classname": "",
                    "value": ""
                  },
                  "wrapperClassName": ""
                },
                "classname": ""
              },
              {
                "fields": {
                  "control": [
                    {
                      "type": "text",
                      "name": "fplc_yield_aftr_qc",
                      "id": "fplc_yield_aftr_qc",
                      "value": "",
                      "placeholder": "",
                      "multiline": false,
                      "classname": "form-control ",
                      "regularExpression": "",
                      "disabled": true,
                      "required": true,
                      "errorMessage": {
                        "noData": "This field is required",
                        "regexFail": ""
                      },
                      "validationButtonType": "FPLC-Submit",
                      "rows": 0,
                      "columns": 0,
                      "onDemandError": false,
                      "customValidation": [
                        {
                          "regularExpression": "/^d+(.d+)?$/",
                          "errorMessage": "Please type a number"
                        }
                      ],
                      "multiError": false,
                      "applicationError": "",
                      "wrapperClassName": "",
                      "requiredErrorMessage": "This field is required",
                      "showInputLabel": true,
                      "labelContent": "text_488",
                      "labelClass": "form-label d-none",
                      "isLabelAfter": false,
                      "showAdditionalInputContent": false,
                      "additionalInputContent": "",
                      "isAdditonalInputAfter": true,
                      "draggable": false
                    }
                  ],
                  "name": "box_491",
                  "id": "box_885",
                  "wrapperType": "BOX",
                  "fields": [],
                  "type": "",
                  "classname": "",
                  "addButton": {
                    "classname": "",
                    "value": ""
                  },
                  "removeButton": {
                    "classname": "",
                    "value": ""
                  },
                  "wrapperClassName": "project-table"
                },
                "classname": ""
              }
            ]
          }
        }
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_133",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_329",
        "fields": [
          {
            "control": [
              {
                "type": "text",
                "name": "exprmntal_notes",
                "id": "exprmntal_notes",
                "value": "",
                "placeholder": "",
                "multiline": true,
                "classname": "form-control textarea",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Experimental Notes/Comments",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_588",
            "classname": "col-12",
            "wrapperType": "BOX",
            "id": "wrapper_345",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      }
    ],
    "type": "",
    "classname": ""
  },

  //Antibody Production & Purification WORKFLOW antibody QC
  {
    "jsonId": 80,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
      {
        "control": [],
        "type": "",
        "name": "wrapper_871",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_17",
        "fields": [
          {
            "control": [
              {
               type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "protocol",
                "id": "protocol",
                "validationButtonType": "AB_QC_submit",
                "classname": "",
                "required": true,
                "apiEndPoints": [
                  {
                    "type": "init",
                    "method": "get",
                    "url": "protocol/protocol-list"
                  },
                  {
                    "type": "change",
                    "method": "get",
                    "Mapto": [
                      {
                        "id": "",
                        "displayKey": "value",
                        "urlParameterValues": {
                          "id": null
                        }
                      }
                    ],
                    "url": "protocol/${id}"
                  }
                ],
                "options": [],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": true,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showInputLabel": true,
                "labelContent": "Protocol",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_392",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_650",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [],
            "type": "",
            "name": "wrapper_916",
            "classname": "col-md-3 mt-4",
            "wrapperType": "BOX",
            "id": "wrapper_62",
            "fields": [
              {
                "control": [
                  {
                    "type": "button",
                    "id": "view",
                    "value": "<div class=\"custom-tooltip\"><p>View </p></div>",
                    "buttonType": "protocol_info",
                    "classname": "view",
                    "divClass": "",
                    "controlEndPoints": [],
                    "disabled": false,
                    "validationEndPoints": [],
                    "invalid": false,
                    "name": "view",
                    "wrapperClassName": "",
                    "draggable": false
                  }
                ],
                "type": "",
                "name": "wrapper_913",
                "classname": "actions",
                "wrapperType": "BOX",
                "id": "wrapper_113",
                "fields": [],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
              }
            ],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_983",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_156",
        "fields": [
          {
            "control": [
              {
                "type": "label",
                "name": "Antibody QC Set Up",
                "classname": "",
                "for": "",
                "required": false,
                "wrapperClassName": "",
                "id": "label_624",
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_95",
            "classname": "col-12  add-heading heading-without-icon ",
            "wrapperType": "BOX",
            "id": "wrapper_276",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
               type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "antbdy_instrument",
                "id": "antbdy_instrument",
                "validationButtonType": "AB_QC_submit",
                "classname": "",
                "required": true,
                "apiEndPoints": [],
                "options": [
                  {
                    "id": 1,
                    "value": "HPLC"
                  },
                  {
                    "id": 2,
                    "value": "Tapestation"
                  }
                ],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": true,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showInputLabel": true,
                "labelContent": "Instrument",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false,
                "controlPoints": [
                  {
                    "type": "change",
                    "value": null,
                    "Mapto": [
                      {
                        "id": "main.wrapper_156.wrapper_63",
                        "type": "hideField"
                      },
                      {
                        "id": "main.wrapper_156.wrapper_62",
                        "type": "hideField"
                      },
                      {
                        "id": "main.wrapper_156.wrapper_635",
                        "type": "hideField"
                      },
                      {
                        "id": "main.wrapper_156.wrapper_624",
                        "type": "hideField"
                      }
                    ]
                  },
                  {
                    "type": "change",
                    "value": "",
                    "Mapto": [
                      {
                        "id": "main.wrapper_156.wrapper_63",
                        "type": "hideField"
                      },
                      {
                        "id": "main.wrapper_156.wrapper_62",
                        "type": "hideField"
                      },
                      {
                        "id": "main.wrapper_156.wrapper_635",
                        "type": "hideField"
                      },
                      {
                        "id": "main.wrapper_156.wrapper_624",
                        "type": "hideField"
                      }
                    ]
                  },
                  {
                    "type": "change",
                    "value": {
                      "id": 1,
                      "value": "HPLC"
                    },
                    "Mapto": [
                      {
                        "id": "main.wrapper_156.wrapper_63",
                        "type": "showField"
                      },
                      {
                        "id": "main.wrapper_156.wrapper_62",
                        "type": "showField"
                      },
                      {
                        "id": "main.wrapper_156.wrapper_635",
                        "type": "hideField"
                      },
                      {
                        "id": "main.wrapper_156.wrapper_624",
                        "type": "hideField"
                      }
                    ]
                  },
                  {
                    "type": "change",
                    "value": {
                      "id": 2,
                      "value": "Tapestation"
                    },
                    "Mapto": [
                      {
                        "id": "main.wrapper_156.wrapper_63",
                        "type": "hideField"
                      },
                      {
                        "id": "main.wrapper_156.wrapper_62",
                        "type": "hideField"
                      },
                      {
                        "id": "main.wrapper_156.wrapper_635",
                        "type": "showField"
                      },
                      {
                        "id": "main.wrapper_156.wrapper_624",
                        "type": "showField"
                      }
                    ]
                  }
                ]
              }
            ],
            "type": "",
            "name": "wrapper_602",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_908",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "antbdy_chrm_column",
                "id": "antbdy_chrm_column",
                "value": "G-Trap FPLC 5mL Column",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "AB_QC_submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Chromatography Column",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_740",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_63",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [],
            "type": "",
            "name": "wrapper_916",
            "classname": "col-md-3 add-buffer",
            "wrapperType": "BOX",
            "id": "wrapper_62",
            "fields": [
              {
                "control": [
                  {
                    "type": "button",
                    "id": "info",
                    "value": "<p>Add Solvent and Buffer details",
                    "buttonType": "add_sb_details",
                    "classname": "btn btn-primary",
                    "divClass": "",
                    "controlEndPoints": [],
                    "disabled": false,
                    "validationEndPoints": [],
                    "invalid": false,
                    "name": "infoicon",
                    "wrapperClassName": "",
                    "draggable": false
                  }
                ],
                "type": "",
                "name": "wrapper_913",
                "classname": "",
                "wrapperType": "BOX",
                "id": "wrapper_113",
                "fields": [],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
              }
            ],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "antbdy_tapestn_mdl",
                "id": "antbdy_tapestn_mdl",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": true,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "AB_QC_submit",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Tapestation Model",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_7404",
            "classname": "col-3 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_635",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [],
            "type": "",
            "name": "wrapper_9167",
            "classname": "col-md-3 add-buffer",
            "wrapperType": "BOX",
            "id": "wrapper_624",
            "fields": [
              {
                "control": [
                  {
                    "type": "button",
                    "id": "info",
                    "value": "<p>Add Instrument details",
                    "buttonType": "add_ins_details",
                    "classname": "btn btn-primary",
                    "divClass": "",
                    "controlEndPoints": [],
                    "disabled": false,
                    "validationEndPoints": [],
                    "invalid": false,
                    "name": "infoicon",
                    "wrapperClassName": "",
                    "draggable": false
                  }
                ],
                "type": "",
                "name": "wrapper_913",
                "classname": "",
                "wrapperType": "BOX",
                "id": "wrapper_113",
                "fields": [],
                "head": "",
                "tabHeadClass": "",
                "draggable": false
              }
            ],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_308",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_122",
        "fields": [
          {
            "control": [
              {
                "type": "label",
                "name": "QC",
                "classname": "",
                "for": "",
                "required": false,
                "wrapperClassName": "",
                "id": "label_95",
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_77",
            "classname": "col-12  add-heading heading-without-icon ",
            "wrapperType": "BOX",
            "id": "wrapper_676",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [],
        "type": "",
        "name": "antibodytable",
        "classname": "table antibody-qc-table",
        "wrapperType": "TABLE",
        "id": "antibodytable",
        "fields": [],
        "head": "",
        "tabHeadClass": "",
        "draggable": false,
        "tableFields": {
          "showHead": true,
          "isVertical": false,
          "isIteratable": false,
          "addButton": {
            "classname": "btn btn-primary",
            "name": "",
            "value": ""
          },
          "removeButton": {
            "classname": "btn btn-danger",
            "name": "",
            "value": ""
          },
          "tableHead": {
            "heading": [
              {
                "value": "Antibody  Name",
                "classname": ""
              },
              {
                "value": "Antibody ID",
                "classname": ""
              },
              {
                "value": "QC Status",
                "classname": ""
              },
              {
                "value": "No of Vials",
                "classname": ""
              }
            ],
            "classname": ""
          },
          "initRecordCount": 1,
          "showRecordCount": false,
          "recordCounterConfig": {
            "classname": "",
            "adjValue": "",
            "isAdjValueAfter": false
          },
          "tableRow": {
            "name": "",
            "classname": "trclass",
            "tableCols": [
              {
                "fields": {
                  "control": [
                    {
                      "type": "text",
                      "name": "antibody_name",
                      "id": "antibody_name",
                      "value": "",
                      "placeholder": "",
                      "multiline": false,
                      "classname": "form-control ",
                      "regularExpression": "",
                      "disabled": true,
                      "required": false,
                      "errorMessage": {
                        "noData": "This field is required",
                        "regexFail": ""
                      },
                      "validationButtonType": "AB_QC_submit",
                      "rows": 0,
                      "columns": 0,
                      "onDemandError": false,
                      "customValidation": [],
                      "multiError": false,
                      "applicationError": "",
                      "wrapperClassName": "",
                      "requiredErrorMessage": "This field is required",
                      "showInputLabel": true,
                      "labelContent": "text_523",
                      "labelClass": "form-label d-none",
                      "isLabelAfter": false,
                      "showAdditionalInputContent": false,
                      "additionalInputContent": "",
                      "isAdditonalInputAfter": true,
                      "draggable": false
                    }
                  ],
                  "head": "",
                  "name": "box_47",
                  "id": "box_38",
                  "wrapperType": "BOX",
                  "fields": [],
                  "type": "",
                  "classname": ""
                },
                "classname": ""
              },
              {
                "fields": {
                  "control": [
                    {
                      "type": "text",
                      "name": "antibody_code",
                      "id": "antibody_code",
                      "value": "",
                      "placeholder": "",
                      "multiline": false,
                      "classname": "form-control ",
                      "regularExpression": "",
                      "disabled": true,
                      "required": false,
                      "errorMessage": {
                        "noData": "This field is required",
                        "regexFail": ""
                      },
                      "validationButtonType": "AB_QC_submit",
                      "rows": 0,
                      "columns": 0,
                      "onDemandError": false,
                      "customValidation": [],
                      "multiError": false,
                      "applicationError": "",
                      "wrapperClassName": "",
                      "requiredErrorMessage": "This field is required",
                      "showInputLabel": true,
                      "labelContent": "text_523",
                      "labelClass": "form-label d-none",
                      "isLabelAfter": false,
                      "showAdditionalInputContent": false,
                      "additionalInputContent": "",
                      "isAdditonalInputAfter": true,
                      "draggable": false
                    }
                  ],
                  "name": "box_851",
                  "id": "box_258",
                  "wrapperType": "BOX",
                  "fields": [],
                  "type": "",
                  "classname": "",
                  "addButton": {
                    "classname": "",
                    "value": ""
                  },
                  "removeButton": {
                    "classname": "",
                    "value": ""
                  },
                  "wrapperClassName": ""
                },
                "classname": ""
              },
              {
                "fields": {
                  "control": [
                    {
                     type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                      "name": "antbdy_qc_status",
                      "id": "antbdy_qc_status",
                      "validationButtonType": "AB_QC_submit",
                      "classname": "",
                      "required": true,
                      "apiEndPoints": [],
                      "options": [
                        {
                          "id": 1,
                          "value": "Passed"
                        },
                        {
                          "id": 2,
                          "value": "Failed"
                        }
                      ],
                      "multiple": false,
                      "isClearable": false,
                      "isStatus": false,
                      "search": true,
                      "singleSelect": true,
                      "showLabel": "value",
                      "errorMessage": {
                        "noData": "This field is required"
                      },
                      "value": "",
                      "imageUrl": "",
                      "disabled": false,
                      "wrapperClassName": "",
                      "connectedArrays": [],
                      "showInputLabel": true,
                      "labelContent": "dropdown_65",
                      "labelClass": "form-label d-none",
                      "isLabelAfter": false,
                      "showAdditionalInputContent": false,
                      "additionalInputContent": "",
                      "isAdditonalInputAfter": true,
                      "draggable": false
                    }
                  ],
                  "name": "box_988",
                  "id": "box_945",
                  "wrapperType": "BOX",
                  "fields": [],
                  "type": "",
                  "classname": "",
                  "addButton": {
                    "classname": "",
                    "value": ""
                  },
                  "removeButton": {
                    "classname": "",
                    "value": ""
                  },
                  "wrapperClassName": ""
                },
                "classname": ""
              },
              {
                "fields": {
                  "control": [
                    {
                      "type": "text",
                      "name": "no_of_vials",
                      "id": "no_of_vials",
                      "value": "",
                      "placeholder": "",
                      "multiline": false,
                      "classname": "form-control ",
                      "regularExpression": "",
                      "disabled": false,
                      "required": true,
                      "errorMessage": {
                        "noData": "This field is required",
                        "regexFail": ""
                      },
                      "validationButtonType": "AB_QC_submit",
                      "rows": 0,
                      "columns": 0,
                      "onDemandError": true,
                      "customValidation": [
                        {
                          "regularExpression": "/^[1-9][0-9]*$/",
                          "errorMessage": "Please type a valid non zero integer"
                        }
                      ],
                      "multiError": false,
                      "applicationError": "",
                      "wrapperClassName": "",
                      "requiredErrorMessage": "This field is required",
                      "showInputLabel": true,
                      "labelContent": "text_523",
                      "labelClass": "form-label d-none",
                      "isLabelAfter": false,
                      "showAdditionalInputContent": false,
                      "additionalInputContent": "",
                      "isAdditonalInputAfter": true,
                      "draggable": false
                    }
                  ],
                  "name": "box_851",
                  "id": "box_258",
                  "wrapperType": "BOX",
                  "fields": [],
                  "type": "",
                  "classname": "",
                  "wrapperClassName": "project-table"
                },
                "classname": ""
              }
            ]
          }
        }
      },
      {
        "control": [],
        "type": "",
        "name": "wrapper_308",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_1224",
        "fields": [
          {
            "control": [
              {
                "type": "datepicker_v2",
                "name": "date_of_storage",
                "id": "date_of_storage",
                "validationButtonType": "submit",
                "readonly": false,
                "range": false,
                "value": "CURRENTDATE",
                "multiple": false,
                "multiAmount": 3,
                "keyboardInput": false,
                "startDateReadOnly": false,
                "endDateReadOnly": false,
                "fordisabledStart": '',
                "fordisabledEnd": "",
                "timer": false,
                "hourFormat": 24,
                "placeholder": "DD-MM-YYYY",
                "startDatePlaceHolder": "MM/DD/YYYY",
                "inputClassName": "form-control date-input",
                "selectedDateClassName": "selectedDate",
                "arrowClassName": "arrow",
                "dayClassName": "dd",
                "todayClassName": "now",
                "monthClassName": "mtn",
                "required": false,
                "classname": "",
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": "Date is not valid!!"
                },
                "errorClass": "errorMessage",
                "dateFormat": "dd/mm/yyyy",
                "disabled": false,
                "wrapperClassName": "",
                "locale": "",
                "dateSeperator": "",
                "inputDateFormat": false,
                "isHostParent": false,
                "showInputLabel": true,
                "labelContent": "Date of Storage",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_763",
            "classname": "col-3",
            "wrapperType": "BOX",
            "id": "wrapper_959",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false,
            "disableValue": {
              "expiry_date": ""
            }
          },
          {
            "control": [
              {
                "type": "text",
                "name": "exprmntal_notes",
                "id": "exprmntal_notes",
                "value": "",
                "placeholder": "",
                "multiline": true,
                "classname": "form-control textarea",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Experimental Notes/Comments",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_588",
            "classname": "col-12 mt-3",
            "wrapperType": "BOX",
            "id": "wrapper_345",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      }
    ],
    "type": "",
    "classname": ""
  },
  // antobody-qc buffer-solvent form
  {
    jsonId: 86,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_666',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_367',
        fields: [
          {
            control: [
              {
                type: 'label',
                name: 'SOLVENT A :',
                classname: 'form-label',
                for: '',
                required: false,
                wrapperClassName: '',
                id: 'label_95',
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_826',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'antbdy_solvent_a',
                id: 'antbdy_solvent_a',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'inventory_item',
                    payload: {
                      user_input: '',
                      perma: 'lab-reagents',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: true,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: false,
                labelContent: 'Source',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [],
                connectedArrays: [],
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_826',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_666',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_3678',
        fields: [
          {
            control: [
              {
                type: 'label',
                name: 'SOLVENT B :',
                classname: 'form-label',
                for: '',
                required: false,
                wrapperClassName: '',
                id: 'label_95',
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_826',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'antbdy_solvent_b',
                id: 'antbdy_solvent_b',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'inventory_item',
                    payload: {
                      user_input: '',
                      perma: 'lab-reagents',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: true,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: false,
                labelContent: 'Source',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [],
                connectedArrays: [],
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_827',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_666',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_3679',
        fields: [
          {
            control: [
              {
                type: 'label',
                name: 'BUFFER A :',
                classname: 'form-label',
                for: '',
                required: false,
                wrapperClassName: '',
                id: 'label_95',
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_826',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'antbdy_buffer_a',
                id: 'antbdy_buffer_a',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'inventory_item',
                    payload: {
                      user_input: '',
                      perma: 'buffers',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: true,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: false,
                labelContent: 'Source',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [],
                connectedArrays: [],
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_828',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_666',
        classname: 'row mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_3675',
        fields: [
          {
            control: [
              {
                type: 'label',
                name: 'BUFFER B :',
                classname: 'form-label',
                for: '',
                required: false,
                wrapperClassName: '',
                id: 'label_95',
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_826',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'antbdy_buffer_b',
                id: 'antbdy_buffer_b',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'inventory_item',
                    payload: {
                      user_input: '',
                      perma: 'buffers',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: true,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: false,
                labelContent: 'Source',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
                controlPoints: [],
                connectedArrays: [],
              },
            ],
            type: '',
            name: 'wrapper_201',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_829',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
 // purification by protein file upload
 {
    jsonId: 91,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'dropdown',
            name: 'file_type',
            id: 'file_type',
            validationButtonType: 'upload',
            classname: '',
            required: true,
            apiEndPoints: [],
            options: [
              {
                key: 1,
                value: 'Absorbance Data',
                data: 'project_data',
              },
              {
                key: 2,
                value: 'Gel Image',
                data: 'gel_image',
              },
            ],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: true,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            value: '',
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Select File Type',
            labelClass: 'form-label',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_801',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_766',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'file',
            name: 'file',
            id: 'file',
            value: 'upload',
            classname: 'btn btn-file',
            fileNameShow: true,
            size: 10,
            url: '',
            functionName: '',
            wrapperClassName: '',
            fileCount: 1,
            sizeKB: '50000',
            allowMultiple: false,
            required: true,
            validationButtonType: 'upload',
            formDataType: 'blob',
            errorMsg: { invalidFormat: 'Invalid File Format', invalidSize: 'File Size exceeded' },
            fileTypes: ['pdf', 'jpeg', 'png', 'jpg', 'xlsx', 'txt', 'ods', 'doc'],
            listClass: '',
            mainClass: '',
            btnDivClass: '',
            showInputLabel: true,
            labelContent: 'Drag and drop files here or browse files  ',
            labelClass: 'form-label',
            requiredErrorMessage: 'This field is required',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
            showViewIcon: false,
            showDownloadIcon: false,
          },
        ],
        type: '',
        name: 'wrapper_379',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_461',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  // purification by fplc file upload
  {
    jsonId: 92,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'dropdown',
            name: 'file_type',
            id: 'file_type',
            validationButtonType: 'upload',
            classname: '',
            required: true,
            apiEndPoints: [],
            options: [
              {
                key: 3,
                value: 'Experimental output file',
                data: 'exp_output_file',
              },
            ],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: true,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            value: {
              key: 3,
              value: 'Experimental output file',
              data: 'exp_output_file',
            },
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Select File Type',
            labelClass: 'form-label',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_801',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_766',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'file',
            name: 'file',
            id: 'file',
            value: 'upload',
            classname: 'btn btn-file',
            fileNameShow: true,
            size: 10,
            url: '',
            functionName: '',
            wrapperClassName: '',
            fileCount: 1,
            sizeKB: '50000',
            allowMultiple: false,
            required: true,
            validationButtonType: 'upload',
            formDataType: 'blob',
            errorMsg: { invalidFormat: 'Invalid File Format', invalidSize: 'File Size exceeded' },
            fileTypes: ['pdf', 'jpeg', 'png', 'jpg', 'xlsx', 'txt', 'ods', 'doc'],
            listClass: '',
            mainClass: '',
            btnDivClass: '',
            showInputLabel: true,
            labelContent: 'Drag and drop files here or browse files  ',
            labelClass: 'form-label',
            requiredErrorMessage: 'This field is required',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
            showViewIcon: false,
            showDownloadIcon: false,
          },
        ],
        type: '',
        name: 'wrapper_379',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_461',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  // purification by qc file upload
  {
    jsonId: 93,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'dropdown',
            name: 'file_type',
            id: 'file_type',
            validationButtonType: 'upload',
            classname: '',
            required: true,
            apiEndPoints: [],
            options: [
              {
                key: 1,
                value: 'Absorbance Data',
                data: 'project_data',
              },
              {
                key: 2,
                value: 'Gel Image',
                data: 'gel_image',
              },
            ],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: true,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            value: '',
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Select File Type',
            labelClass: 'form-label',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_801',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_766',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'file',
            name: 'file',
            id: 'file',
            value: 'upload',
            classname: 'btn btn-file',
            fileNameShow: true,
            size: 10,
            url: '',
            functionName: '',
            wrapperClassName: '',
            fileCount: 1,
            sizeKB: '50000',
            allowMultiple: false,
            required: true,
            validationButtonType: 'upload',
            formDataType: 'blob',
            errorMsg: { invalidFormat: 'Invalid File Format', invalidSize: 'File Size exceeded' },
            fileTypes: ['pdf', 'jpeg', 'png', 'jpg', 'xlsx', 'txt'],
            listClass: '',
            mainClass: '',
            btnDivClass: '',
            showInputLabel: true,
            labelContent: 'Drag and drop files here or browse files  ',
            labelClass: 'form-label',
            requiredErrorMessage: 'This field is required',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
            showViewIcon: false,
            showDownloadIcon: false,
          },
        ],
        type: '',
        name: 'wrapper_379',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_461',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  
  // antobody-qc instrument-details form
  {
    "jsonId":99,
    "control":[
       
    ],
    "head":"",
    "name":"main",
    "id":"main",
    "wrapperType":"REGULAR",
    "fields":[
       {
          "control":[
             
          ],
          "type":"",
          "name":"wrapper_666",
          "classname":"row mb-3",
          "wrapperType":"BOX",
          "id":"wrapper_367",
          "fields":[
             {
                "control":[
                   {
                      "type":"label",
                      "name":"RIN/DIN Screen Tape Lot No :",
                      "classname":"form-label",
                      "for":"",
                      "required":false,
                      "wrapperClassName":"",
                      "id":"label_95",
                      "draggable":false
                   }
                ],
                "type":"",
                "name":"wrapper_201",
                "classname":"col-5 mb-3",
                "wrapperType":"BOX",
                "id":"wrapper_826",
                "fields":[
                   
                ],
                "head":"",
                "tabHeadClass":""
             },
             {
                "control":[
                   {
                      "type":"text",
                      "name":"antbdy_rd_scrn_tp_lt",
                      "id":"antbdy_rd_scrn_tp_lt",
                      "value":"",
                      "placeholder":"",
                      "multiline":false,
                      "classname":"form-control ",
                      "regularExpression":"",
                      "disabled":false,
                      "required":false,
                      "errorMessage":{
                         "noData":"This field is required",
                         "regexFail":""
                      },
                      "validationButtonType":"",
                      "rows":0,
                      "columns":0,
                      "onDemandError":true,
                      "customValidation":[
                         {
                            "regularExpression":"/^\\d+(\\.\\d+)?$/",
                            "errorMessage":"Please Type a Number"
                         }
                      ],
                      "multiError":false,
                      "applicationError":"",
                      "wrapperClassName":"",
                      "requiredErrorMessage":"This field is required",
                      "showInputLabel":false,
                      "labelContent":"Last Name",
                      "labelClass":"form-label",
                      "isLabelAfter":false,
                      "showAdditionalInputContent":false,
                      "additionalInputContent":"",
                      "isAdditonalInputAfter":true,
                      "draggable":false
                   }
                ],
                "type":"",
                "name":"wrapper_201",
                "classname":"col-6 mb-3",
                "wrapperType":"BOX",
                "id":"wrapper_826",
                "fields":[
                   
                ],
                "head":"",
                "tabHeadClass":""
             }
          ],
          "head":"",
          "tabHeadClass":"",
          "draggable":false
       },
       {
          "control":[
             
          ],
          "type":"",
          "name":"wrapper_666",
          "classname":"row mb-3",
          "wrapperType":"BOX",
          "id":"wrapper_3678",
          "fields":[
             {
                "control":[
                   {
                      "type":"label",
                      "name":"Buffer Used :",
                      "classname":"form-label",
                      "for":"",
                      "required":false,
                      "wrapperClassName":"",
                      "id":"label_95",
                      "draggable":false
                   }
                ],
                "type":"",
                "name":"wrapper_201",
                "classname":"col-5 mb-3",
                "wrapperType":"BOX",
                "id":"wrapper_826",
                "fields":[
                   
                ],
                "head":"",
                "tabHeadClass":""
             },
             {
                "control":[
                   {
                      "type":"dropdown",
                      "name":"antbdy_tape_buf_used",
                      "id":"antbdy_tape_buf_used",
                      "validationButtonType":"",
                      "classname":"",
                      "required":false,
                      "apiEndPoints":[
                         {
                            "type":"init",
                            "method":"post",
                            "url":"inventory_item",
                            "payload":{
                               "user_input":"",
                               "perma":"buffers"
                            }
                         }
                      ],
                      "options":[
                         
                      ],
                      "multiple":false,
                      "isClearable":false,
                      "isStatus":false,
                      "search":false,
                      "singleSelect":true,
                      "showLabel":"value",
                      "errorMessage":{
                         "noData":"This field is required"
                      },
                      "value":"",
                      "imageUrl":"",
                      "disabled":false,
                      "wrapperClassName":"",
                      "showInputLabel":false,
                      "labelContent":"Source",
                      "labelClass":"form-label",
                      "isLabelAfter":false,
                      "showAdditionalInputContent":false,
                      "additionalInputContent":"",
                      "isAdditonalInputAfter":true,
                      "draggable":false,
                      "controlPoints":[
                         
                      ],
                      "connectedArrays":[
                         
                      ]
                   }
                ],
                "type":"",
                "name":"wrapper_201",
                "classname":"col-6 mb-3",
                "wrapperType":"BOX",
                "id":"wrapper_827",
                "fields":[
                   
                ],
                "head":"",
                "tabHeadClass":""
             }
          ],
          "head":"",
          "tabHeadClass":"",
          "draggable":false
       },
       {
          "control":[
             
          ],
          "type":"",
          "name":"wrapper_666",
          "classname":"row mb-3",
          "wrapperType":"BOX",
          "id":"wrapper_3679",
          "fields":[
             {
                "control":[
                   {
                      "type":"label",
                      "name":"No of Samples Loaded :",
                      "classname":"form-label",
                      "for":"",
                      "required":false,
                      "wrapperClassName":"",
                      "id":"label_95",
                      "draggable":false
                   }
                ],
                "type":"",
                "name":"wrapper_201",
                "classname":"col-5 mb-3",
                "wrapperType":"BOX",
                "id":"wrapper_826",
                "fields":[
                   
                ],
                "head":"",
                "tabHeadClass":""
             },
             {
                "control":[
                   {
                      "type":"text",
                      "name":"antbdy_tape_smpl_cnt",
                      "id":"antbdy_tape_smpl_cnt",
                      "value":"",
                      "placeholder":"",
                      "multiline":false,
                      "classname":"form-control ",
                      "regularExpression":"",
                      "disabled":false,
                      "required":false,
                      "errorMessage":{
                         "noData":"This field is required",
                         "regexFail":""
                      },
                      "validationButtonType":"",
                      "rows":0,
                      "columns":0,
                      "onDemandError":true,
                      "customValidation":[
                         {
                            "regularExpression":"/^\\d+(\\.\\d+)?$/",
                            "errorMessage":"Please Type a Number"
                         }
                      ],
                      "multiError":false,
                      "applicationError":"",
                      "wrapperClassName":"",
                      "requiredErrorMessage":"This field is required",
                      "showInputLabel":false,
                      "labelContent":"Last Name",
                      "labelClass":"form-label",
                      "isLabelAfter":false,
                      "showAdditionalInputContent":false,
                      "additionalInputContent":"",
                      "isAdditonalInputAfter":true,
                      "draggable":false
                   }
                ],
                "type":"",
                "name":"wrapper_201",
                "classname":"col-6 mb-3",
                "wrapperType":"BOX",
                "id":"wrapper_828",
                "fields":[
                   
                ],
                "head":"",
                "tabHeadClass":""
             }
          ],
          "head":"",
          "tabHeadClass":"",
          "draggable":false
       },
       {
          "control":[
             
          ],
          "type":"",
          "name":"wrapper_666",
          "classname":"row mb-3",
          "wrapperType":"BOX",
          "id":"wrapper_3675",
          "fields":[
             {
                "control":[
                   {
                      "type":"label",
                      "name":"Sample Vol Loaded (µL) :",
                      "classname":"form-label",
                      "for":"",
                      "required":false,
                      "wrapperClassName":"",
                      "id":"label_95",
                      "draggable":false
                   }
                ],
                "type":"",
                "name":"wrapper_201",
                "classname":"col-5 mb-3",
                "wrapperType":"BOX",
                "id":"wrapper_826",
                "fields":[
                   
                ],
                "head":"",
                "tabHeadClass":""
             },
             {
                "control":[
                   {
                      "type":"text",
                      "name":"antbdy_tape_smpl_vol",
                      "id":"antbdy_tape_smpl_vol",
                      "value":"",
                      "placeholder":"",
                      "multiline":false,
                      "classname":"form-control ",
                      "regularExpression":"",
                      "disabled":false,
                      "required":false,
                      "errorMessage":{
                         "noData":"This field is required",
                         "regexFail":""
                      },
                      "validationButtonType":"",
                      "rows":0,
                      "columns":0,
                      "onDemandError":true,
                      "customValidation":[
                         {
                            "regularExpression":"/^\\d+(\\.\\d+)?$/",
                            "errorMessage":"Please Type a Number"
                         }
                      ],
                      "multiError":false,
                      "applicationError":"",
                      "wrapperClassName":"",
                      "requiredErrorMessage":"This field is required",
                      "showInputLabel":false,
                      "labelContent":"Last Name",
                      "labelClass":"form-label",
                      "isLabelAfter":false,
                      "showAdditionalInputContent":false,
                      "additionalInputContent":"",
                      "isAdditonalInputAfter":true,
                      "draggable":false
                   }
                ],
                "type":"",
                "name":"wrapper_201",
                "classname":"col-6 mb-3",
                "wrapperType":"BOX",
                "id":"wrapper_829",
                "fields":[
                   
                ],
                "head":"",
                "tabHeadClass":""
             }
          ],
          "head":"",
          "tabHeadClass":"",
          "draggable":false
       }
    ],
    "type":"",
    "classname":""
 },
]