//Protocol filter
export const ProtocolDynamicJSON =[
  {
    jsonId: 63,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        head: '',
        name: 'main',
        id: 'main',
        wrapperType: 'REGULAR',
        fields: [
          {
            control: [],
            type: '',
            name: 'wrapper_275',
            classname: 'row',
            wrapperType: 'BOX',
            id: 'wrapper_548',
            fields: [
              {
                control: [
                  {
                    type: 'text',
                    name: 'protocol_name',
                    id: 'protocolName',
                    value: '',
                    placeholder: '',
                    multiline: false,
                    classname: 'form-control ',
                    regularExpression: '',
                    disabled: false,
                    required: false,
                    errorMessage: { noData: 'This field is required', regexFail: '' },
                    validationButtonType: '',
                    rows: 0,
                    columns: 0,
                    onDemandError: false,
                    customValidation: [],
                    multiError: false,
                    applicationError: '',
                    wrapperClassName: '',
                    requiredErrorMessage: 'This field is required',
                    showInputLabel: true,
                    labelContent: 'Protocol Name',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_541',
                classname: 'col-6 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_205',
                fields: [],
                head: '',
                tabHeadClass: '',
              },
              {
                control: [
                  {
                    type: 'dropdown',
                    name: 'protocol_lab_experiment',
                    id: 'Labexperiments',
                    validationButtonType: '',
                    classname: '',
                    required: false,
                    apiEndPoints: [
                      {
                        type: 'init',
                        method: 'post',
                        url: 'masterdata',
                        payload: {
                          data_types: ['project-type-list'],
                        },
                      },
                    ],
                    options: [],
                    multiple: false,
                    isClearable: false,
                    isStatus: false,
                    search: true,
                    singleSelect: true,
                    showLabel: 'value',
                    errorMessage: { noData: 'This field is required' },
                    value: '',
                    imageUrl: '',
                    disabled: false,
                    wrapperClassName: '',
                    connectedArrays: [],
                    showBlankInPlaceholder: false,
                    blankDataList: [],
                    showInputLabel: true,
                    labelContent: ' Lab Experiments',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_426',
                classname: 'col-6 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_569',
                fields: [],
                head: '',
                tabHeadClass: '',
              },
              {
                control: [
                  {
                    type: 'dropdown',
                    name: 'protocol_status',
                    id: 'protocol_status',
                    validationButtonType: '',
                    classname: '',
                    required: false,
                    apiEndPoints: [],
                    options: [
                      { id: 1, value: 'Pending' },
                      { id: 2, value: 'Approved' },
                      { id: 3, value: 'Rejected' },
                    ],
                    multiple: false,
                    isClearable: false,
                    isStatus: false,
                    search: true,
                    singleSelect: true,
                    showLabel: 'value',
                    errorMessage: { noData: 'This field is required' },
                    value: '',
                    imageUrl: '',
                    disabled: false,
                    wrapperClassName: '',
                    connectedArrays: [],
                    showBlankInPlaceholder: false,
                    blankDataList: [],
                    showInputLabel: true,
                    labelContent: 'Status',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_252',
                classname: 'col-6 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_291',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
              },
              {
                control: [
                  {
                    type: 'dropdown',
                    name: 'protocol_proposed_by',
                    id: 'protocol_proposed_by',
                    validationButtonType: 'submit',
                    classname: '',
                    required: false,
                    apiEndPoints: [
                      {
                        type: 'init',
                        method: 'get',
                        url: 'user_management/users_list',
                      },
                    ],
                    options: [],
                    multiple: false,
                    isClearable: false,
                    isStatus: false,
                    search: true,
                    singleSelect: true,
                    showLabel: 'value',
                    errorMessage: { noData: 'This field is required' },
                    value: '',
                    imageUrl: '',
                    disabled: false,
                    wrapperClassName: '',
                    connectedArrays: [],
                    showBlankInPlaceholder: false,
                    blankDataList: [],
                    showInputLabel: true,
                    labelContent: 'Proposed by',
                    labelClass: 'form-label ',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_995',
                classname: 'col-6 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_192',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
              },
              {
                control: [
                  {
                    type: 'datepicker_v2',
                    name: 'protocol_date',
                    id: 'proposed_date',
                    validationButtonType: '',
                    range: true,
                    value: '',
                    multiple: false,
                    multiAmount: 3,
                    keyboardInput: false,
                    startDateReadOnly: false,
                    endDateReadOnly: false,
                    fordisabledStart: '',
                    fordisabledEnd: '',
                    timer: false,
                    hourFormat: 24,
                    placeholder: 'DD-MM-YYYY',
                    startDatePlaceHolder: 'MM/DD/YYYY',
                    inputClassName: 'form-control date-input',
                    selectedDateClassName: 'selectedDate',
                    arrowClassName: 'arrow',
                    dayClassName: 'dd',
                    todayClassName: 'now',
                    monthClassName: 'mtn',
                    regularExpression:
                      /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}\s+to\s+(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/,
                    required: false,
                    classname: '',
                    errorMessage: {
                      noData: 'This field is required',
                      regexFail: 'Date is not valid!!',
                    },
                    dateFormat: 'dd/mm/yyyy',
                    disabled: false,
                    wrapperClassName: '',
                    locale: 'en-US',
                    dateSeperator: '',
                    inputDateFormat: false,
                    isHostParent: false,
                    showInputLabel: true,
                    labelContent: 'Date From-To',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_512',
                classname: 'col-6 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_935',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
              },
              {
                control: [
                  {
                    type: 'text',
                    name: 'protocol_version',
                    id: 'Version',
                    value: '',
                    placeholder: '',
                    multiline: false,
                    classname: 'form-control ',
                    regularExpression: '',
                    disabled: false,
                    required: false,
                    errorMessage: { noData: 'This field is required', regexFail: '' },
                    validationButtonType: '',
                    rows: 0,
                    columns: 0,
                    onDemandError: false,
                    customValidation: [],
                    multiError: false,
                    applicationError: '',
                    wrapperClassName: '',
                    requiredErrorMessage: 'This field is required',
                    showInputLabel: true,
                    labelContent: 'Version',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_234',
                classname: 'col-6 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_521',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
              },
            ],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        type: '',
        classname: '',
      },
    ],
    type: '',
    classname: '',
  },

  //Add new Protocol
  {
    jsonId: 64,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_275',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_548',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'protocol_name',
                id: 'protocol_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Protocol Name',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_541',
            classname: 'col-3  mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_205',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'protocol_lab_experiment',
                id: 'protocol_lab_experiment',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'masterdata',
                    payload: {
                      data_types: ['project-type-list'],
                    },
                  },
                ],
                options: [],
                multiple: true,
                isClearable: true,
                isStatus: false,
                search: true,
                singleSelect: false,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                connectedArrays: [],
                showBlankInPlaceholder: false,
                blankDataList: [],
                showInputLabel: true,
                labelContent: ' Lab Experiments',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_426',
            classname: 'col-3  mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_569',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'protocol_proposed_by',
                id: 'protocol_proposed_by',
                validationButtonType: 'submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'get',
                    url: 'user_management/users_list',
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: true,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                connectedArrays: [],
                showBlankInPlaceholder: false,
                blankDataList: [],
                showInputLabel: true,
                labelContent: ' Proposed By',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_252',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_291',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'protocol_version',
                id: 'protocol_version',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Version',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_995',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_192',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'protocol_date',
                id: 'protocol_date',
                validationButtonType: 'submit',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                errorClass: 'errorMessage',
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: 'en-GB',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Date',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_512',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_935',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'protocol_description',
                id: 'protocol_description',
                value: '',
                placeholder: '',
                multiline: true,
                classname: 'form-control  textarea',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Description',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_123',
            classname: 'col-12 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_179',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  // Protocol file upload
  {
    jsonId: 65,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
            name: 'file_type',
            id: 'file_type',
            validationButtonType: 'upload',
            classname: '',
            required: true,
            apiEndPoints: [],
            options: [
              {
                key: 'protocol',
                value: 'Protocol',
              },
            ],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: true,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            value: {
              key: 'protocol',
              value: 'Protocol',
            },
            imageUrl: '',
            disabled: true,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Select File Type',
            labelClass: 'form-label',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_801',
        classname: 'col-12 mb-3 d-none',
        wrapperType: 'BOX',
        id: 'wrapper_766',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'file',
            name: 'file',
            id: 'file',
            value: 'upload',
            classname: 'btn btn-file',
            fileNameShow: true,
            size: 10,
            url: '',
            functionName: '',
            wrapperClassName: '',
            fileCount: 1,
            sizeKB: '50000',
            allowMultiple: false,
            required: true,
            validationButtonType: 'upload',
            formDataType: 'blob',
            errorMsg: { invalidFormat: 'Invalid File Format', invalidSize: 'File Size exceeded' },
            fileTypes: ['pdf', 'docx', 'doc'],
            listClass: '',
            mainClass: '',
            btnDivClass: '',
            showInputLabel: true,
            labelContent: 'Drag and drop files here or browse files  ',
            labelClass: 'form-label',
            requiredErrorMessage: 'This field is required',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
            showViewIcon: false,
            showDownloadIcon: false,
          },
        ],
        type: '',
        name: 'wrapper_379',
        classname: 'col-12 mb-3 drag-and-drop',
        wrapperType: 'BOX',
        id: 'wrapper_461',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },]